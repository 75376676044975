import { Suspense } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import { MaterialThemeProvider } from "./theme/MaterialUiThemeProvider";
import MetaRoutes from "./routes/MetaRoutes";

function App() {
  return (
    <MaterialThemeProvider>
      <Suspense fallback={<h6 className="lazyLoading">Loading...</h6>}>
        <MetaRoutes />
      </Suspense>
    </MaterialThemeProvider>
  );
}

export default App;
