import { FormControl, InputLabel, ListItem, ListItemText } from "@mui/material";
import React from "react";
import OTARatePlans from "./OTARatePlans";
import { useState } from "react";
import { useEffect } from "react";

export default function PmsRatePlansList({
  elm,
  selectedChannel,
  otaData,
  pmsRatePlans,
  selctedOtaRoom,
  currentRate,
  pmsData,
  setPmsData,
}) {
  const [ratePlanStyle, setRatePlanStyle] = useState({ color: "#2d68b2" });

  useEffect(() => {
    if (!currentRate?.channelRatePlans?.[selectedChannel?.channelName]) {
      setRatePlanStyle({ color: "#2d68b2" });
    } else {
      const otaRatePlan =
        currentRate?.channelRatePlans?.[selectedChannel?.channelName];
      if (
        otaData?.ratePlanList?.filter(
          (rates) => rates?.ratePlanId === otaRatePlan
        ).length > 0
      ) {
        setRatePlanStyle({ color: "#1BC5BD" });
      } else {
        setRatePlanStyle({ color: "#ffc0cb" });
      }
    }
  }, [currentRate]);
  return (
    <>
      <ListItem
        sx={{
          backgroundColor: "#f2f2f2",
          marginTop: "1rem",
          marginBottom: "1rem",
          height: "3rem",
          borderRadius: "2px",
          padding: "5px",
        }}
        disableGutters
        secondaryAction={
          <FormControl
            sx={{
              width: "300px",
            }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label">
              Rate Plans for
              {
                otaData?.roomTypeList?.filter(
                  (rooms) => rooms?.roomTypeId === selctedOtaRoom
                )[0]?.roomTypeName
              }
            </InputLabel>
            <OTARatePlans
              elm={elm}
              selectedChannel={selectedChannel}
              otaData={otaData}
              pmsRatePlans={pmsRatePlans}
              selctedOtaRoom={selctedOtaRoom}
              currentRate={currentRate}
              pmsData={pmsData}
              setPmsData={setPmsData}
              setRatePlanStyle={setRatePlanStyle}
            />
          </FormControl>
        }
      >
        <ListItemText
          sx={ratePlanStyle}
          primary={`${currentRate?.ratePlanName} (${currentRate.ratePlanId})`}
        />
      </ListItem>
    </>
  );
}
