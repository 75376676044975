import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { Box, Button, ClickAwayListener, Stack } from "@mui/material";
import { handleDjangoDataRequest } from "../../utils/apiUtils";
import { formatDateRange } from "../../utils/Utils";

import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
let timer = 0;

export default function PerfStats({ hotelId }) {
  const [data, setData] = useState();
  //   [
  //   { date: "2023-02-11", Revenue: 1890, Booking: 4 },
  //   { date: "2023-04-03", Revenue: 2780, Booking: 3 },
  //   { date: "2023-06-01", Revenue: 4000, Booking: 2 },
  //   { date: "2023-02-12", Revenue: 1890, Booking: 4 },
  //   { date: "2023-04-04", Revenue: 2780, Booking: 3 },
  //   { date: "2023-06-02", Revenue: 4000, Booking: 2 },
  //   { date: "2023-02-13", Revenue: 1890, Booking: 4 },
  //   { date: "2023-04-05", Revenue: 2780, Booking: 3 },
  // ]

  //----------------------
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}:${month}:${day}`;
  };
  //-----------
  const sortDateArray = (dates) => {
    const dateObjects = dates.map((elm) => ({
      date: new Date(elm.date),
      Revenue: elm.Revenue,
      Booking: elm.Booking,
    }));
    dateObjects.sort((a, b) => a.date - b.date);

    const sortedData = dateObjects.map((elm) => ({
      date: formatDate(elm.date),
      Revenue: elm.Revenue.toFixed(2),
      Booking: elm.Booking,
    }));

    return sortedData;
  };

  const handleData = (data) => {
    const arr = [];
    data.map((elm) => {
      const singleItem = {};
      singleItem.date = elm.metric;
      singleItem.Revenue = elm.b_rev;
      singleItem.Booking = elm.b_count;
      arr.push(singleItem);
    });
    const sortedData = sortDateArray(arr);
    setData(sortedData);
  };
  //------------------------
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleShowButton = () => {
    if (dateRange) {
      const formatedDate = formatDateRange(dateRange);
      console.log(formatedDate);
      handleDjangoDataRequest(
        `api/v2/metaAdmin/metaPerfStatLogsForHotel/?hotel_id=${hotelId}&daterange=${formatedDate}`
      )
        .then((data) => {
          handleData(data);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    handleShowButton();
  }, []);
  //----------------Hide Unhide------------

  const [showLeftAxis, setShowLeftAxis] = useState(true);
  const [showRightAxis, setShowRightAxis] = useState(true);

  const toggleAxis = () => {
    timer++;
    if (timer % 3 === 0) {
      setShowLeftAxis(true);
      setShowRightAxis(true);
    } else if (timer % 2 === 0) {
      setShowLeftAxis(false);
      setShowRightAxis(true);
    } else {
      setShowLeftAxis(true);
      setShowRightAxis(false);
    }
  };
  //---------------Skeleton--------

  //open and close date picker
  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ display: "flex", marginBottom: 1 }}>
        <Stack spacing={4} className="position-relative" sx={{ mt: 1 }}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: "relative" }}>
              <Button variant="outlined" onClick={handleClick} fullWidth>
                {moment(dateRange[0].startDate).format("DD-MM-YY")} {" to "}
                {moment(dateRange[0].endDate).format("DD-MM-YY")}
              </Button>
              {open ? (
                <Box sx={styles}>
                  <Stack spacing={3}>
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRange}
                    />
                    <Stack
                      className="position-relative w-100 d-flex justify-content-between"
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="custom-button"
                        onClick={() => {
                          handleShowButton();
                          handleClickAway();
                          console.log(dateRange);
                        }}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              ) : null}
            </Box>
          </ClickAwayListener>
        </Stack>
        <Button
          variant="custom-button"
          sx={{ mb: 1, mt: 1, ml: 2 }}
          onClick={handleShowButton}
        >
          Show Data
        </Button>
      </Box>

      <Box>
        <LineChart width={1200} height={550} data={data} onClick={toggleAxis}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          {showLeftAxis && <YAxis yAxisId="revenue" />}
          {showRightAxis && <YAxis yAxisId="bookingId" orientation="right" />}

          <Tooltip />
          <Legend />
          {showLeftAxis && (
            <Line
              yAxisId="revenue"
              type="monotone"
              dataKey="Revenue"
              stroke="#8884d8"
            />
          )}
          {showRightAxis && (
            <Line
              yAxisId="bookingId"
              type="monotone"
              dataKey="Booking"
              stroke="#82ca9d"
            />
          )}
        </LineChart>
      </Box>
    </>
  );
}
