import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { handleSpringDataMutationRequest } from "../../utils/apiUtils";

export default function AddEmail({ handleClose }) {
  const [status, setStatus] = useState();
  const [email, setEmail] = useState();
  const handleSubmit = () => {
    handleSpringDataMutationRequest(
      "POST",
      `api/v2/metaAdmin/setEmailValidationStatus/`,
      {
        email: email,
        send_enabled: status,
      }
    )
      .then((data) => {
        console.log(data);
        handleClose();
      })
      .catch((err) => console.log(err));
  };
  return (
    <Card>
      <CardContent>
        {/* <CardHeader></CardHeader> */}
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-standard-label">
            Send Status
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Send Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value={"on"}>ON</MenuItem>
            <MenuItem value={"off"}>OFF</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          size="small"
          id="outlined-basic"
          label="Email Id"
          variant="outlined"
          sx={{ mt: 2 }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Divider sx={{ m: 1 }} />
        <Button
          color="error"
          variant="outlined"
          sx={{ mr: 1 }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button variant="custom-button" onClick={handleSubmit}>
          Submit
        </Button>
      </CardContent>
    </Card>
  );
}
