import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import PmsRatePlansList from "./PmsRatePlansList";

export default function RatePlanMapping({
  elm,
  selectedChannel,
  otaData,
  pmsRatePlans,
  pmsData,
  setPmsData,
}) {
  const [roomTypeStyle, setRoomTypeStyle] = useState({ color: "#676767" });

  const [selctedOtaRoom, setSelectedOtaRoom] = useState("");
  const handleRoomSelection = (data) => {
    setSelectedOtaRoom(data);
    if (data) {
      setRoomTypeStyle({ color: "#1BC5BD" });
    } else {
      setRoomTypeStyle({ color: "#2d68b2" });
    }
    //------Updating Pms Room Type data if data is there----------
    const myPmsData = { ...pmsData };
    const newRoomObject = {};
    newRoomObject[selectedChannel?.channelName] = data;
    myPmsData.roomTypes.map((item) => {
      if (item?.roomTypeId === elm?.roomTypeId) {
        item.channelRoomTypes = newRoomObject;
      }
    });
    //------Removing rate plans data on every selection on Hotel Room ----------
    myPmsData.ratePlans.map((item) => {
      if (item?.roomTypeId === elm?.roomTypeId) {
        item.channelRatePlans[selectedChannel?.channelName] = "";
      }
    });
    setPmsData(myPmsData);
  };
  useEffect(() => {
    if (!elm?.channelRoomTypes[selectedChannel?.channelName]) {
      setRoomTypeStyle({ color: "#2d68b2" });
    } else {
      const otaRoomId = elm?.channelRoomTypes[selectedChannel?.channelName];
      if (
        otaData?.roomTypeList.filter((room) => room.roomTypeId === otaRoomId)
          .length > 0
      ) {
        setRoomTypeStyle({ color: "#1BC5BD" });
      } else {
        setRoomTypeStyle({ color: "#ffc0cb" });
      }
    }
    elm?.channelRoomTypes[selectedChannel?.channelName] &&
      setSelectedOtaRoom(elm?.channelRoomTypes[selectedChannel?.channelName]);
  }, [elm]);
  useEffect(() => {}, [selctedOtaRoom]);
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={roomTypeStyle}
      >
        <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          {elm?.roomTypeName}({elm?.roomTypeId})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* -------Single Room Type in List Tags--------------------- */}
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem
            disableGutters
            secondaryAction={
              <FormControl sx={{ width: "300px" }} size="small">
                <InputLabel id="demo-simple-select-label">
                  <b>Room Types on OTA</b>
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => handleRoomSelection(e.target.value)}
                  label="Room Types on OTA DB"
                  value={selctedOtaRoom}
                >
                  <MenuItem value="">---Select---</MenuItem>
                  {otaData?.roomTypeList?.map((elm, idx) => (
                    <MenuItem
                      key={idx}
                      value={elm?.roomTypeId}
                      disabled={
                        pmsData?.roomTypes?.filter(
                          (room) =>
                            room?.channelRoomTypes[
                              selectedChannel?.channelName
                            ] === elm?.roomTypeId
                        ).length > 0
                      }
                    >
                      {elm?.roomTypeName}({elm?.roomTypeId})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          >
            <ListItemText
              sx={[{ marginBottom: "40px" }, roomTypeStyle]}
              primary={`${elm.roomTypeName} (${elm.roomTypeId}) `}
            />
          </ListItem>
        </List>
        {/* --------------Rate Plans List----------------- */}

        <Divider>Rate Plans</Divider>

        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {pmsRatePlans
            ?.filter((rates) => rates?.roomTypeId === elm.roomTypeId)
            .map((currentRate, idx) => (
              <PmsRatePlansList
                elm={elm}
                selectedChannel={selectedChannel}
                otaData={otaData}
                pmsRatePlans={pmsRatePlans}
                selctedOtaRoom={selctedOtaRoom}
                currentRate={currentRate}
                pmsData={pmsData}
                setPmsData={setPmsData}
              />
            ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
