import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import CircularProgress from "@mui/material/CircularProgress";
import RatePlanMapping from "./RatePlanMapping";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../utils/apiUtils";
import MappingSkeleton from "./MappingSkeleton";

function RoomTypeMapping({ hide, hotelId }) {
  const [allChannels, setAllChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState();
  const [channelHotelId, setChannelHotelId] = useState();
  const [tempChannelHotelId, setTempChannelHotelId] = useState();
  const [findLoader, setFindLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [pmsData, setPmsData] = useState();
  const [otaData, setOtaData] = useState();

  const gettingChannelsList = () => {
    handleSpringDataRequest(
      `core/api/v1/cm/get-all-channels/?hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((res) => {
        setAllChannels(
          res.sort(function (a, b) {
            return a.channelName.localeCompare(b.channelName);
          })
        );
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    gettingChannelsList();
  }, []);

  const handleFindButton = () => {
    setChannelHotelId(tempChannelHotelId);
  };

  const fetchingPmsData = () => {
    setPmsData("");
    handleSpringDataRequest(
      `core/api/v1/cm/get-hotel-channel-config?channels=${selectedChannel?.channelId}&hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((elm) => {
        if (elm?.channelHotels[selectedChannel?.channelName]) {
          setTempChannelHotelId(
            elm.channelHotels[selectedChannel?.channelName].split("-")[0]
          );
          setChannelHotelId(
            elm.channelHotels[selectedChannel?.channelName].split("-")[0]
          );
        }
        setPmsData(elm);
        // console.log(elm);
      })
      .catch((err) => {
        setPmsData("");
        console.log(err);
      });
  };

  const fetchingOTAData = () => {
    setOtaData("");
    setFindLoader(true);
    handleSpringDataRequest(
      `core/api/v1/cm/get-product-info?channelHotelId=${channelHotelId}&channelName=${selectedChannel?.channelName}&hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((data) => {
        setFindLoader(false);

        if (data?.error) {
          toast.error(data?.error);
          setOtaData("");
        } else {
          setOtaData(data);
        }
      })
      .catch((err) => {
        setFindLoader(false);
        setOtaData("");
        toast.error("Something went wrong");
      });
  };
  const handleChannelSelection = (item) => {
    setTempChannelHotelId("");
    setChannelHotelId("");
    setSelectedChannel(item);
  };
  useEffect(() => {
    if (selectedChannel) {
      if (channelHotelId) {
        fetchingOTAData();
      } else {
        fetchingPmsData();
      }
    }
  }, [channelHotelId, selectedChannel]);

  const handleSubmitButton = () => {
    const myPmsData = { ...pmsData };
    const newRoomObject = {};
    newRoomObject[selectedChannel?.channelName] = channelHotelId;
    myPmsData.channelHotels = newRoomObject;
    setPmsData(myPmsData);
    console.log(myPmsData);

    setSubmitLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/cm/apply-hotel-channel-config/?hotel_id=${hotelId}&hotelId=${hotelId}`,
      myPmsData
    )
      .then(() => {
        setSubmitLoader(false);
        hide();
        toast.success("Channel Mapped Successfully");
      })
      .catch(() => {
        setSubmitLoader(false);
      });
  };
  return (
    <>
      <div style={styles.form}>
        <FormControl size="small" sx={{ width: "300px" }}>
          <InputLabel id="demo-simple-select-label">Channel</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => {
              handleChannelSelection(e.target.value);
            }}
            label="Channel"
            value={selectedChannel}
          >
            {allChannels?.map((elm) => (
              <MenuItem value={elm}>{elm.channelName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedChannel?.channelName && (
          <TextField
            size="small"
            sx={{ width: "300px" }}
            label="Channel Hotel id"
            placeholder="Enter Channel Hotel id here"
            onChange={(e) => setTempChannelHotelId(e.target.value.trim())}
            value={tempChannelHotelId}
            name="schedule_name"
            id="schedule_name"
          />
        )}
        <Button
          variant="custom-button"
          size="small"
          onClick={handleFindButton}
          disabled={findLoader}
        >
          Find
          {findLoader ? <CircularProgress size={15} color="inherit" /> : ""}
        </Button>
      </div>
      {findLoader ? <MappingSkeleton /> : null}
      {pmsData &&
        otaData &&
        pmsData?.roomTypes?.map((elm, idx) => (
          <RatePlanMapping
            key={idx}
            elm={elm}
            selectedChannel={selectedChannel}
            otaData={otaData}
            pmsRatePlans={pmsData?.ratePlans}
            pmsData={pmsData}
            setPmsData={setPmsData}
          />
        ))}
      {pmsData && otaData && (
        <Button
          variant="custom-button"
          disabled={submitLoader}
          sx={{
            marginTop: "20px",
            marginLeft: "20px",
            margingBottom: "20px",
            width: "300px",
          }}
          onClick={handleSubmitButton}
        >
          Submit
          {submitLoader && <CircularProgress size={15} color="inherit" />}
        </Button>
      )}
    </>
  );
}

export default RoomTypeMapping;

const styles = {
  form: {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  blue: { color: "blue" },
  green: { color: "green" },
  red: { color: "red" },
};
