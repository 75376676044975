import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { handleSpringDataRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";
import TableSkeleton from "../../components/TableSkeleton";
import TableAirbnb from "../../components/TableAirbnb";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { hover } from "@testing-library/user-event/dist/hover";
import CircularProgress from "@mui/material/CircularProgress";

const SyncAll = ({ value }) => {
  const [loader, setLoader] = useState(false);
  const handleButton = () => {
    setLoader(true);
    handleSpringDataRequest(`cm/api/airbnb/syncall/?listing_id=${value}`)
      .then((elm) => {
        toast.success(elm);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoader(false);
      });
  };
  return (
    <Button
      variant="custom-button"
      sx={{ height: "1.8rem" }}
      onClick={handleButton}
    >
      {loader ? <CircularProgress size={24} /> : "Sync All"}
    </Button>
  );
};

const SyncRatesAndAvailability = ({ value }) => {
  const [loader, setLoader] = useState(false);
  const handleButton = () => {
    setLoader(true);
    handleSpringDataRequest(`cm/api/airbnb/syncratesavail/?listing_id=${value}`)
      .then((elm) => {
        toast.success(elm);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoader(false);
      });
  };
  return (
    <Button
      variant="custom-button"
      sx={{ height: "1.8rem" }}
      onClick={handleButton}
    >
      {loader ? <CircularProgress size={24} /> : "Sync Rates And Availability"}
    </Button>
  );
};

const SetHashAvailability = ({ value }) => {
  const [loader, setLoader] = useState(false);
  const handleButton = () => {
    setLoader(true);
    handleSpringDataRequest(`cm/api/airbnb/hasavail/?listing_id=${value}`)
      .then((elm) => {
        toast.success(elm);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoader(false);
      });
  };
  return (
    <Button
      variant="custom-button"
      sx={{ height: "1.8rem" }}
      onClick={handleButton}
    >
      {loader ? <CircularProgress size={24} /> : <CheckIcon />}
    </Button>
  );
};

const SetHashNoAvailability = ({ value }) => {
  const [loader, setLoader] = useState(false);
  const handleButton = () => {
    setLoader(true);
    handleSpringDataRequest(`cm/api/airbnb/hasnoavail/?listing_id=${value}`)
      .then((elm) => {
        toast.success(elm);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoader(false);
      });
  };
  return (
    <Button
      variant="outlined"
      sx={{
        background: "rgba(255, 0, 0, 0.6)",
        color: "white",
        height: "1.8rem",
        "&:hover": {
          background: "rgba(255, 0, 0, 0.7)",
        },
      }}
      onClick={handleButton}
    >
      {loader ? <CircularProgress size={24} /> : <CloseIcon />}
    </Button>
  );
};
const ReadyForReview = ({ value }) => {
  const [loader, setLoader] = useState(false);
  const handleButton = () => {
    setLoader(true);
    handleSpringDataRequest(`cm/api/airbnb/reqapproval/?listing_id=${value}`)
      .then((elm) => {
        toast.success(elm);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoader(false);
      });
  };
  return (
    <Button
      variant="custom-button"
      sx={{ height: "1.8rem" }}
      onClick={handleButton}
    >
      {loader ? <CircularProgress size={24} /> : "Ready For Review"}
    </Button>
  );
};

export default function AirBnb({ hotelId }) {
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "listing",
      headerName: "Listing",
      floatingFilter: true,
      width: 140,
    },
    {
      field: "listing",
      headerName: "Sync All",
      cellRenderer: SyncAll,
      width: 170,
    },
    {
      field: "listing",
      headerName: "Sync Rates And Availability",
      cellRenderer: SyncRatesAndAvailability,
      minWidth: 300,
    },
    {
      field: "listing",
      headerName: "Set Hash Availability",
      cellRenderer: SetHashAvailability,
      width: 170,
    },
    {
      field: "listing",
      headerName: "Set Hash No Availability",
      cellRenderer: SetHashNoAvailability,
      width: 185,
    },
    {
      field: "listing",
      headerName: "Ready For Review",
      cellRenderer: ReadyForReview,
      minWidth: 200,
    },
  ]);
  //---------Handeling Row Data---------------
  const handleRowData = (data) => {
    const myData = [];
    data.map((elm) => {
      myData.push({ listing: elm });
    });
    console.log(myData);
    setRowData(myData);
  };

  //---------------Skeleton--------
  const [table, setTable] = useState(false);
  useEffect(() => {
    setTable(false);
    handleSpringDataRequest(
      `core/api/v1/cm/get-airbnb-listing?hotelId=${hotelId}`
    )
      .then((data) => {
        setTable(true);
        handleRowData(data);
      })
      .catch((err) => setTable(true));
  }, []);

  //---------------Host Input and List Input---------------
  const [authCode, setAuthCode] = useState();
  const [hostId, setHostId] = useState();

  const handleAuthCode = () => {
    setLoaderAuth(true);
    handleSpringDataRequest(
      `core/api/v1/cm/create-token-for-airbnb?authCode=${authCode}`
    )
      .then(() => {
        toast.success("Auth Code Successfully Set");
        setLoaderAuth(false);
      })
      .catch(() => {
        toast.error("Something went wrong");
        setLoaderAuth(false);
      });
  };
  const handleHostId = () => {
    setLoaderListing(true);
    handleSpringDataRequest(
      `core/api/v1/cm/create-listing-for-airbnb?hotelId=${hotelId}&hostId=${hostId}`
    )
      .then(() => {
        toast.success("Host Id Successfully Set");
        setLoaderListing(false);
      })
      .catch(() => {
        toast.error("Something went wrong");
        setLoaderListing(false);
      });
  };
  //---------Loaders----------
  const [loaderAuth, setLoaderAuth] = useState(false);
  const [loaderListing, setLoaderListing] = useState(false);

  return (
    <>
      <Grid item xs={12} sx={{ boxShadow: 8 }}>
        <Card sx={{ marginBottom: 2 }}>
          <CardHeader
            title={"Authenticate The Code"}
            subheader={"Authenticate The Code From Airbnb"}
          >
            <Divider />
          </CardHeader>
          <Divider />
          <CardContent>
            <TextField
              size="small"
              id="outlined-basic"
              label="AuthCode"
              variant="outlined"
              value={authCode}
              onChange={(e) => setAuthCode(e.target.value)}
            />
            <Button
              variant="custom-button"
              sx={{ ml: 2 }}
              onClick={handleAuthCode}
            >
              {loaderAuth ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 8 }}>
        <Card sx={{ marginBottom: 2 }}>
          <CardHeader title={"Create Listing"} subheader={"Create Listing"}>
            <Divider />
          </CardHeader>
          <Divider />
          <CardContent>
            <TextField
              size="small"
              id="outlined-basic"
              label="Host ID"
              variant="outlined"
              value={hostId}
              onChange={(e) => setHostId(e.target.value)}
            />
            <Button
              variant="custom-button"
              sx={{ ml: 2 }}
              onClick={handleHostId}
            >
              {loaderListing ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Box>
        {table ? (
          rowData ? (
            <TableAirbnb rowData={rowData} columnDefs={columnDefs} />
          ) : (
            <Box
              sx={{
                position: "absolute",
                transform: "translate(-50%, -50%)",
                top: "80%",
                left: "50%",
              }}
            >
              <Typography>No Data Found.......</Typography>
            </Box>
          )
        ) : (
          <TableSkeleton />
        )}
      </Box>
    </>
  );
}
