import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Table from "../../components/Table";
import { formatDateRange, toRegulerCase } from "../../utils/Utils";
import { handleDjangoDataRequest } from "../../utils/apiUtils";
import TableSkeleton from "../../components/TableSkeleton";
import moment from "moment";
import { DateRange } from "react-date-range";

export default function ChannelLogsForHotel() {
  //-------table Data-----------
  const [rowData, setRowData] = useState();

  const [columnDefs, setColumnDefs] = useState();
  const handleColumn = (data) => {
    if (data) {
      const culumnData = Object.keys(data[0]).map((elm) => ({
        field: elm,
        headerName: toRegulerCase(elm),
      }));
      setColumnDefs(culumnData);
    }
  };

  //--------date Range-----------
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // const [dateRange, setdateRange] = useState();

  //open and close date picker
  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  const handleShowButton = () => {
    const formatedDate = formatDateRange(dateRange);
    console.log(formatedDate);
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaCMLogsForHotel/?hotel_id=${hotelId}&daterange=${formatedDate}`
    )
      .then((data) => {
        console.log(data);
        handleColumn(data);
        setRowData(data);
      })
      .catch((err) => console.log(err));
  };
  //------------Hotel Id Management--------------
  const [inputHotelId, setInputHotelId] = useState();
  const [hotelId, setHotelId] = useState();
  const handleChangeHotelId = (e) => {
    setHotelId("");
    setInputHotelId(e);
  };
  return (
    <Box>
      <Typography sx={{ fontSize: "13px" }} gutterBottom>
        Please enter the hotel ID
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Hotel Id"
            variant="outlined"
            size="small"
            value={inputHotelId}
            onChange={(e) => handleChangeHotelId(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            variant="custom-button"
            onClick={() => setHotelId(inputHotelId)}
          >
            Proceed
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ m: 1 }} />
      {/* ------------Devider--------------------------------------------------- */}

      {hotelId && (
        <Box>
          <Box sx={{ display: "flex", marginBottom: 1 }}>
            <Stack spacing={4} className="position-relative" sx={{ mt: 1 }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ position: "relative" }}>
                  <Button variant="outlined" onClick={handleClick} fullWidth>
                    {moment(dateRange[0].startDate).format("DD-MM-YY")} {" to "}
                    {moment(dateRange[0].endDate).format("DD-MM-YY")}
                  </Button>
                  {open ? (
                    <Box sx={styles}>
                      <Stack spacing={3}>
                        <DateRange
                          editableDateInputs={true}
                          onChange={(item) => setDateRange([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={dateRange}
                        />
                        <Stack
                          className="position-relative w-100 d-flex justify-content-between"
                          direction="row"
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => setOpen(false)}
                            color="error"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="custom-button"
                            onClick={() => {
                              handleShowButton();
                              handleClickAway();
                              console.log(dateRange);
                            }}
                          >
                            Apply
                          </Button>
                        </Stack>
                      </Stack>
                    </Box>
                  ) : null}
                </Box>
              </ClickAwayListener>
            </Stack>

            <Button
              variant="custom-button"
              sx={{ mb: 1, mt: 1, ml: 2 }}
              onClick={handleShowButton}
            >
              Show Data
            </Button>
          </Box>
          {rowData ? (
            <Table rowData={rowData} columnDefs={columnDefs} />
          ) : (
            <TableSkeleton />
          )}
        </Box>
      )}
    </Box>
  );
}
