import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { handleSpringDataMutationRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";

export default function EditChannel({
  singleChannel,
  handleClose,
  setRefresh,
}) {
  const [channelName, setChannelName] = useState("");
  const [channelUsername, setChannelUsername] = useState("");
  const [channelPassword, setChannelPassword] = useState("");
  const [channelProvidedAPIKey, setChannelProvidedAPIKey] = useState("");
  const [additionalPricePercent, setAdditionalPricePercent] = useState("");
  const [
    stayFlexiChannelAPIkeyexposedtoextranet,
    setStayFlexiChannelAPIkeyexposedtoextranet,
  ] = useState();
  const assigningvalues = () => {
    setChannelName(singleChannel?.channelName);
    setChannelUsername(singleChannel?.username);
    setChannelPassword(singleChannel?.password);
    setChannelProvidedAPIKey(singleChannel?.apiKey);
    setAdditionalPricePercent(singleChannel?.addPricePercent);
    setStayFlexiChannelAPIkeyexposedtoextranet(singleChannel?.chApiKey);
  };

  useEffect(() => {
    assigningvalues();
  }, [singleChannel]);

  //--------------------

  const handleSubmit = () => {
    const payload = {
      addPricePercent: additionalPricePercent,
      apiKey: channelProvidedAPIKey,
      channelName: channelName,
      password: channelPassword,
      username: channelUsername,
    };
    handleSpringDataMutationRequest(
      "POST",
      `metaAdmin/api/v1/add-channel`,
      payload
    ).then(() => {
      toast.success("Channel Updated Successfully");
      handleClose();
      setRefresh(new Date());
    });
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label="Channel Name"
                variant="outlined"
                value={channelName}
                onChange={(e) => setChannelName(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label="Channel UserName"
                variant="outlined"
                value={channelUsername}
                onChange={(e) => setChannelUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label="Channel Password"
                variant="outlined"
                value={channelPassword}
                onChange={(e) => setChannelPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label="Channel Provided API Key"
                variant="outlined"
                value={channelProvidedAPIKey}
                onChange={(e) => setChannelProvidedAPIKey(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label="Additional Price Percent"
                variant="outlined"
                value={additionalPricePercent}
                onChange={(e) => setAdditionalPricePercent(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                size="small"
                id="outlined-basic"
                label="StayFlexi Channel API key exposed to extranet"
                variant="outlined"
                value={stayFlexiChannelAPIkeyexposedtoextranet}
                // onChange={(e) =>
                //   setStayFlexiChannelAPIkeyexposedtoextranet(e.target.value)
                // }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Divider sx={{ mt: 1 }} /> */}
      <Button
        color="error"
        variant="outlined"
        sx={{ m: 2 }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button variant="custom-button" sx={{ m: 2 }} onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
}
