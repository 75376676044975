import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { handleSpringDataMutationRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";

export default function DeleteChannel({
  singleChannel,
  handleClose,
  setRefresh,
}) {
  const handlConfirm = () => {
    console.log(singleChannel);

    handleSpringDataMutationRequest(
      "DELETE",
      `metaAdmin/api/v1/delete-channel?channelId=${singleChannel?.channelId}`
    )
      .then(() => {
        toast.success("Channel Deleted Successfully");
        handleClose();
        setRefresh(new Date());
      })
      .catch(() => toast.error("Something went wrong"));
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Are you sure want to delete channel
            </Grid>
            <Grid item xs={10}>
              {" "}
              <Button
                color="error"
                variant="outlined"
                sx={{ m: 2 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="custom-button"
                sx={{ m: 2 }}
                onClick={handlConfirm}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
