// import moment from "moment";

export const toAbsoluteUrl = (pathname) => {
  return process.env.PUBLIC_URL + pathname;
};

export const formatDateRange = (givenDateRange) => {
  const startDate = givenDateRange[0].startDate;
  const startMonth = startDate.toLocaleString("default", { month: "long" });
  const startDay = startDate.getDate();
  const startYear = startDate.getFullYear();

  // Get the end date by adding 10 days to the original date
  const endDate = givenDateRange[0].endDate;
  const endMonth = endDate.toLocaleString("default", { month: "long" });
  const endDay = endDate.getDate();
  const endYear = endDate.getFullYear();

  // Format the dates into the desired string format
  const formattedStartDate = `${startMonth}%20${startDay}%2C%20${startYear}`;
  const formattedEndDate = `${endMonth}%20${endDay}%2C%20${endYear}`;
  const formattedDateRange = `${formattedStartDate}%20-%20${formattedEndDate}`;

  // console.log(formattedDateRange); // Output: "April%204%2C%202023%20-%20April%2014%2C%202023"
  return formattedDateRange;
};

export function toRegulerCase(string) {
  // Remove any non-alphanumeric characters and split into words
  var words = string.replace(/[^0-9a-zA-Z]+/g, " ").split(" ");

  // Capitalize the first letter of each word
  var camelWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back together with space
  var camelString = camelWords.join(" ");

  return camelString;
}

//------------Returning values from params.
export const handleparamValue = (key) => {
  const searchParams = new URLSearchParams(
    new URL(window.location.href).search
  );
  return searchParams.get(key);
};

// const currentUrl = window.location.href;

export const formatdate = (newDate) => {
  // Get the date components
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");

  // Get the time components
  const hours = String(newDate.getHours()).padStart(2, "0");
  const minutes = String(newDate.getMinutes()).padStart(2, "0");
  const seconds = String(newDate.getSeconds()).padStart(2, "0");

  // Format the date and time
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

export const apiDateFormat = (mydate) => {
  const dateString = mydate;
  const dateParts = dateString.split("-");

  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  const formattedDate = `${day}-${month}-${year}`;

  const date = new Date();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedTime = `${hours}:${minutes}:${seconds}`;
  console.log(mydate);
  return `${formattedDate}%20${formattedTime}`;
};
