import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function OTARatePlans({
  elm,
  selectedChannel,
  otaData,
  pmsRatePlans,
  selctedOtaRoom,
  currentRate,
  pmsData,
  setPmsData,
  setRatePlanStyle,
}) {
  const [selectedOtaRatePlan, setSelectedOtaRatePlan] = useState("");
  const handleRatePlans = (item) => {
    setSelectedOtaRatePlan(item);
    if (item) {
      setRatePlanStyle({ color: "#1BC5BD" });
    } else {
      setRatePlanStyle({ color: "#2d68b2" });
    }
    //-----updating PMS Rate plans data-------------------------------
    const myPmsData = { ...pmsData };
    const newRoomObject = {};
    newRoomObject[selectedChannel?.channelName] = item;
    myPmsData?.ratePlans.map((elm) => {
      if (
        elm?.roomTypeId === currentRate?.roomTypeId &&
        elm?.ratePlanId === currentRate?.ratePlanId
      ) {
        elm.channelRatePlans = newRoomObject;
      }
    });
    setPmsData(myPmsData);
  };
  useEffect(() => {
    currentRate?.channelRatePlans?.[selectedChannel?.channelName] &&
      setSelectedOtaRatePlan(
        currentRate?.channelRatePlans?.[selectedChannel?.channelName]
      );
  }, [currentRate]);
  return (
    <Select
      size="small"
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      onChange={(e) => handleRatePlans(e.target.value)}
      label={`Rate Plans for
      ${
        otaData?.roomTypeList?.filter(
          (rooms) => rooms?.roomTypeId === selctedOtaRoom
        )[0]?.roomTypeName
      }`}
      value={selectedOtaRatePlan}
    >
      <MenuItem value="">---Select----</MenuItem>
      {otaData?.ratePlanList
        ?.filter(
          (elm) =>
            elm?.roomTypeList.filter(
              (room) => room?.roomTypeId === selctedOtaRoom
            ).length > 0
        )
        .map((rate) => (
          <MenuItem
            value={rate.ratePlanId}
            disabled={
              pmsData?.ratePlans?.filter(
                (room) =>
                  room?.channelRatePlans[selectedChannel?.channelName] ===
                    rate?.ratePlanId &&
                  room?.roomTypeId === currentRate?.roomTypeId
              ).length > 0
            }
          >
            {rate.ratePlanName} ({rate.ratePlanId})
          </MenuItem>
        ))}
    </Select>
  );
}
