export const nextTenYearOld = () => {
  const currentDate = new Date();
  const futureYear = currentDate.getFullYear() + 10;
  const futureDate = new Date(
    futureYear,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  return futureDate.toISOString().slice(0, 10);
};
