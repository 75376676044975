import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  handleDjangoDataRequest,
  handleSpringDataMutationRequest,
} from "../../utils/apiUtils";
import { toast } from "react-toastify";

export default function CreateUser({ handleClose, selectedUser, setRefresh }) {
  console.log(selectedUser);
  const [access, setAccess] = useState({});

  const handleListOfAccess = (data) => {
    const myData = {};

    data?.map((elm) => {
      myData[elm.feature_name] = false;
    });
    if (selectedUser?.email) {
      setUserName(selectedUser.email);
      setId(selectedUser.id);
      Object.keys(selectedUser.accessmap).map(
        (elm) => selectedUser.accessmap[elm] && (myData[elm] = true)
      );
    }
    setAccess(myData);
  };
  useEffect(() => {
    handleDjangoDataRequest("api/v2/metaAdmin/metaAdminGetAllAccessControl")
      .then((data) => {
        handleListOfAccess(data?.meta_access_control);
      })
      .catch((err) => console.log(err));
  }, []);
  //---------------------------
  const [id, setId] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //---------------------Handle Switch component------------------

  const handleSwitch = (elm) => {
    const tempObj = { ...access };
    tempObj[elm] = !tempObj[elm];
    setAccess(tempObj);
  };
  //-------------Handle Submit Button------------
  const handleSubmitButton = () => {
    if (password !== confirmPassword) {
      toast.error("Password and Confirm Password should same.");
      return;
    }
    const features = Object.keys(access).filter((elm) => access[elm]);
    if (features.length === 0) {
      toast.error("Please select minimum one feature");
      return;
    }
    const payload = {
      username: username,
      password: password,
      isMetaAdmin: "YES",
      featureSet: features,
    };
    let api;
    if (selectedUser?.email) {
      api = "user/metaAdmin/update";
    } else {
      api = "user/metaAdmin/save";
    }
    handleSpringDataMutationRequest("POST", api, payload)
      .then((data) => {
        toast.success("Operation Success");
        setRefresh(new Date());
        handleClose();
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast.error(err?.response?.data);
        } else {
          toast.error("Something went wrong, try again later.");
        }
      });
  };
  return (
    <>
      <Box>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography>Login Credentials</Typography>
            <Typography sx={{ color: "gray", fontSize: "13px" }}>
              Configure the login details for your user
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label="Username (Enter a valid email address)"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  size="small"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  size="small"
                  id="outlined-basic"
                  label="Confirm Password"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography>Update/Assign Roles</Typography>
            <Typography sx={{ fontSize: "13px", color: "gray" }}>
              Select the pages that you want this user to be able to access. For
              e.g housekeeping staff would just need "HouseKeeping"
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ height: 380, overflow: "scroll", p: 1 }}>
              <Grid container spacing={2}>
                {access &&
                  Object.keys(access).map((elm) => (
                    <Grid item xs={6}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ border: "1px solid gray", borderRadius: 1 }}
                      >
                        <Grid item xs={10}>
                          <Typography sx={{ fontSize: "13px" }}>
                            {elm}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Switch
                            checked={access[elm]}
                            onClick={() => handleSwitch(elm)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}

                {/* -----------------------------Button-------------------------------------- */}
                <Grid item xs={12}></Grid>
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button variant="custom-button" onClick={handleSubmitButton}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
