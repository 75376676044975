import {
  CircularProgress,
  Grid,
  Button,
  IconButton,
  Tooltip,
  Switch,
  Box,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import "../propertyDashboard/styles/AccessControlModal.css";
import { Table } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import { handleSpringDataRequest } from "../../utils/apiUtils";
import GroupFeaturesLoader from "./Loader/GroupFeaturesLoader";

const AutoReplenish = ({ hotelId, emailId }) => {
  const [access, setAccess] = useState("");
  const [loader, setLoader] = useState(false);

  const handleCheckboxChange = (row) => {
    const newRow = { ...row };
    newRow.enabled = row?.enabled === "yes" ? "no" : "yes";
    setAccess(newRow);
  };

  const saveGroupAccess = () => {
    setLoader(true);
    handleSpringDataRequest(
      `core/api/v1/pc/update-auto-replenish-inv?hotelId=${access?.hotelId}&type=${access?.enabled}`
    )
      .then(() => {
        setLoader(false);
        getReplenishData();
      })
      .catch(() => {
        setLoader(false);
      });
  };

  //------------------replenish-inv------------

  const getReplenishData = () => {
    handleSpringDataRequest(
      `core/api/v1/pc/get-auto-replenish-inv/?hotelId=${hotelId}`
    )
      .then((data) => {
        setAccess(data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getReplenishData();
  }, []);

  return (
    <Box>
      <Card style={{ width: "100%", height: "100%" }}>
        <Card.Body style={{ width: "100%" }}>
          {!access || loader ? (
            <GroupFeaturesLoader />
          ) : (
            <>
              <Grid item xs={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="d-flex flex-column w-100">
                    <div
                      style={{
                        overflowY: "scroll",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Hotel id</th>
                            <th>Access</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span className="d-flex align-item-center">
                                <p
                                  className="mr-1 mb-0"
                                  style={{ marginTop: "7px" }}
                                >
                                  {access?.hotelId}
                                </p>
                                <Tooltip
                                  title={
                                    <Typography sx={{ fontSize: "18px" }}>
                                      {access?.hotelId}
                                    </Typography>
                                  }
                                  placement="right"
                                >
                                  <IconButton>
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </span>
                            </td>
                            <td>
                              <Switch
                                checked={access?.enabled === "yes"}
                                onChange={() => handleCheckboxChange(access)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Button
                className="float-right mr-4"
                variant="custom-button"
                onClick={() => saveGroupAccess()}
                disabled={loader}
              >
                Save
                {loader && (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                )}
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </Box>
  );
};

export default AutoReplenish;
