import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { handleSpringDataRequest } from "../utils/apiUtils";
import { useEffect } from "react";
import SingleRateRow from "./rates/SingleRateRow";
import SearchIcon from "@mui/icons-material/Search";

export default function RateShoper() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [columns, setColumns] = useState([
    { id: "id", label: "Id", width: "20%", align: "left" },
    { id: "bcom_url", label: "Bcom url", width: "20%", align: "left" },
    {
      id: "city_location",
      label: "City location",
      width: "20%",
      align: "left",
    },
    { id: "zip_code", label: "Zip code", width: "20%", align: "left" },
    { id: "id", label: "Action", width: "20%", align: "right" },
  ]);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getRateShoperData = () => {
    handleSpringDataRequest(`core/api/v1/rate-shopper/get-all-zipcodes`)
      .then((data) => {
        setRows(data);
        console.log(data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getRateShoperData();
  }, []);

  const handleSearch = () => {};

  return (
    <Box sx={{ mt: 2, mb: 2, p: 2 }}>
      <Typography sx={{ fontSize: "1.5rem" }}>Rate Shopper Report</Typography>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "50%",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by zip code....."
              inputProps={{
                "aria-label": "Search by zip code.....",
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column) => (
                    <TableCell
                      key={column.label}
                      align={column.align}
                      style={{ width: column.width }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  ?.filter((elm) =>
                    searchText?.trim()?.length > 0
                      ? elm?.zip_code?.includes(searchText?.trim())
                      : true
                  )
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <SingleRateRow
                        row={row}
                        getRateShoperData={getRateShoperData}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
