import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Table from "../components/Table";
import TableSkeleton from "../components/TableSkeleton";
import { useState } from "react";
import { toRegulerCase } from "../utils/Utils";
import AddEmail from "./email/AddEmail";
import { handleDjangoDataRequest } from "../utils/apiUtils";
import Nodata from "../components/Nodata";
import SearchIcon from "@mui/icons-material/Search";

export default function Email() {
  //--------Moda Style and Handling-----------
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  //------Rowdata-------
  const [rowData, setRowData] = useState();
  //ColumnDef --------------
  const [columnDefs, setColumnDefs] = useState();
  //----Setting Column Data----------------
  const handleColumn = (data) => {
    const newColumn = Object.keys(data)
      .filter((elm) => elm !== "id")
      .map((elm) => ({ field: elm, headerName: toRegulerCase(elm) }));
    setColumnDefs(newColumn);
  };
  //---------------Skeleton--------
  const [table, setTable] = useState(false);
  // Fetching Row Data------------------
  const [email, setEmail] = useState("");
  const handleShowdata = () => {
    setTable(false);
    handleDjangoDataRequest(
      `api/v2/metaAdmin/getEmailsSendStatusData/?phrase=${encodeURIComponent(
        email
      )}`
    )
      .then((data) => {
        setTable(true);
        console.log(data);
        handleColumn(data[0]);
        setRowData(data);
      })
      .catch((err) => setTable(true));
  };
  useEffect(() => {
    handleShowdata();
  }, []);
  return (
    <>
      <Card sx={{ mb: 1 }}>
        {/* <CardHeader
          title={"Email Services"}
          subheader={
            "Used for charging swiped cards or manually added cards in Folio"
          }
        ></CardHeader> */}
      </Card>
      {/* <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: "13px", ml: 1 }}>
            All Emails in our system with send status
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Button type="submit" variant="custom-button" onClick={handleOpen}>
            Add Email
          </Button>
        </Grid>
      </Grid> */}
      {/* <Divider sx={{ m: 1 }} /> */}
      <Box sx={{ display: "flex", gap: 6, alignItems: "center", pl: 2 }}>
        <Typography sx={{ fontSize: "1.5rem" }}>Email Services</Typography>
        <Button
          type="submit"
          variant="custom-button"
          onClick={handleOpen}
          sx={{ height: "43px", width: "200px" }}
        >
          Add Email
        </Button>
        <Paper
          component="form"
          sx={{
            mt: 1,
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "50%",
          }}
        >
          <InputBase
            value={email}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search email....."
            inputProps={{
              "aria-label": "Search email.....",
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleShowdata}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      {/* <TextField
        sx={{ ml: 1, mr: 2 }}
        size="small"
        required
        label="Search By Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      /> */}

      {/* <Button type="submit" variant="custom-button" onClick={handleShowdata}>
        Show Data
      </Button> */}
      <Divider sx={{ m: 1 }} />
      {table ? (
        rowData ? (
          <Table rowData={rowData} columnDefs={columnDefs} />
        ) : (
          <Nodata />
        )
      ) : (
        <TableSkeleton />
      )}

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ ml: 1 }}>Add Email</Typography>
          <CloseIcon
            fontSize="small"
            sx={{ position: "absolute", top: 6, right: 6, cursor: "pointer" }}
            onClick={handleClose}
          />
          <Divider sx={{ mt: 1 }} />
          <AddEmail handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
}
