// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  // const Token = useSelector((state) => state.Token);
  const Token = localStorage.getItem("token");
  if (Token) {
    return children;
  }
  return <Navigate to={{ pathname: "/" }} />;
  // return <UserLogin />;
};
