import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    components: {
      //   // Name of the component
      MuiFab: {
        defaultProps: { variant: "extended" },
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            textTransform: "none",
            background: "linear-gradient(-150deg, #1ab394, #1a91ae)",
            color: "white",
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "custom-button" },
            style: {
              textTransform: "none",
              backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",
              color: "white",
            },
          },
        ],
      },
      MuiLoadingButton: {
        variants: [
          {
            props: { variant: "custom-button" },
            style: {
              textTransform: "none",
              backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",
              color: "white",
            },
          },
        ],
      },
    },

    // components: {
    //   MuiIcon: {
    //     defaultProps: {
    //       color: "linear-gradient(#1b95ac 0%,#1bb195 100%)"
    //     }
    //   }
    // },
    // direction: "rtl",
    typography: {
      fontFamily: ["Maven Pro"].join(","),
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#1BC5BD",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#3783e7",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#cc5965",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // MuiOutlinedInput: {
      //   style: {
      //     borderColor: "linear-gradient(#1b95ac 0%,#1bb195 100%)",
      //   },
      // },
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
