import React from "react";
import { Box } from "@mui/system";
import { Divider, Modal } from "@mui/material";
import RoomTypeMapping from "./RoomTypeMapping";

const ChannelModal = (props) => {
  return (
    <div>
      <Modal
        open={props.show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            height: "100%",
            width: "70%",
            backgroundColor: "#ffffff",
            overflow: "auto",
          }}
        >
          <span
            style={{
              position: "absolute",
              left: "16px",
              top: "16px",
              cursor: "pointer",
            }}
            onClick={props.hide}
          >
            X
          </span>
          <Divider sx={{ mt: 6 }} />
          <RoomTypeMapping hide={props.hide} hotelId={props.hotelId} />
          {/* <ChannelAdd hide={props.hide} hotelId={props.hotelId} /> */}
        </Box>
      </Modal>
    </div>
  );
};

export default ChannelModal;
