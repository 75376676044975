import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Card,
  ClickAwayListener,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Table from "../components/Table";
import { toRegulerCase } from "../utils/Utils";
import { handleDjangoDataRequest } from "../utils/apiUtils";
import TableSkeleton from "../components/TableSkeleton";
import Nodata from "../components/Nodata";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";

export default function Commission() {
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState();
  const [gridApi, setGridApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);
  const logedUserEmail = localStorage.getItem("logedUserEmail");
  //---------------Skeleton--------
  const [table, setTable] = useState(false);

  const handleColumn = (data) => {
    if (data?.length > 0) {
      const culumnData = Object.keys(data[0]).map((elm) => ({
        field: elm,
        headerName: toRegulerCase(elm),
      }));
      setColumnDefs(culumnData);
    } else {
      setColumnDefs(null);
    }
  };

  //-------------------
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleShowButton = () => {
    setTable(false);
    handleDjangoDataRequest(
      `api/v2/reports/getReportData/?report_type=sfCommissionTransfersReportMeta&start_date=${moment(
        dateRange[0].startDate
      ).format("YYYY-MM-DD")}&end_date=${moment(dateRange[0].endDate).format(
        "YYYY-MM-DD"
      )}`
    )
      .then((data) => {
        setTable(true);
        handleColumn(data?.report_data);
        if (data?.report_data?.length > 0) {
          setRowData(data?.report_data);
        } else {
          setRowData(null);
        }
      })
      .catch((err) => {
        setTable(true);
        setRowData(null);
        setColumnDefs(null);
      });
  };

  //open and close date picker
  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };
  useEffect(() => {
    handleShowButton();
  }, []);

  //----------
  const gridOptions = {
    // ... other grid options

    rowClassRules: {
      "custom-row-class-1": (params) =>
        params.data.current_invoice_status === "Pending" &&
        params.data.current_payment_status === "Pending",
      "custom-row-class-2": (params) =>
        params.data.current_invoice_status === "Raised" &&
        params.data.current_payment_status === "Pending",

      // Add more rules as needed based on your conditions
    },
  };

  const onSelectionChanged = (event) => {
    setSelectedRowsLength(event.api.getSelectedRows().length);
    setSelectedRows(event.api.getSelectedRows());
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onExportClick = () => {
    selectedRowsLength === 0
      ? gridApi.exportDataAsCsv()
      : gridApi.exportDataAsCsv({ onlySelected: true });
  };
  const getDownloadType = (dType) => {
    dType === "1" && onExportClick();
  };

  return (
    <div>
      <Card sx={{ mb: 1 }}>
        {/* <CardHeader
        title={"Commission Transfer Report"}
        subheader={
          "Used for charging swiped cards or manually added cards in Folio"
        }
        ></CardHeader> */}
      </Card>
      <Box sx={{ display: "flex", gap: 4, mt: 2, mb: 2, pl: 2 }}>
        <Typography sx={{ fontSize: "1.5rem" }}>
          Commission Transfer Report
        </Typography>
        <Stack spacing={4} className="position-relative">
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: "relative" }}>
              <Button
                variant="outlined"
                onClick={handleClick}
                fullWidth
                sx={{ height: "35.69px", textTransform: "none" }}
              >
                {moment(dateRange[0].startDate).format("Do MMMM, YYYY")}{" "}
                {" to "}
                {moment(dateRange[0].endDate).format("Do MMMM, YYYY")}
              </Button>
              {open ? (
                <Box sx={styles}>
                  <Stack spacing={3}>
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRange}
                    />
                    <Stack
                      className="position-relative w-100 d-flex justify-content-between"
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="custom-button"
                        onClick={() => {
                          handleShowButton();
                          handleClickAway();
                          console.log(dateRange);
                        }}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              ) : null}
            </Box>
          </ClickAwayListener>
        </Stack>
        <Button variant="custom-button" onClick={handleShowButton}>
          Show Data
        </Button>

        {logedUserEmail === "venkatesh.sakamuri@stayflexi.com" && (
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Export as</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={"0"}
                label="Export as"
                onChange={(e) => getDownloadType(e.target.value)}
              >
                <MenuItem value={"0"}>Select option</MenuItem>
                <MenuItem value={"1"}>Export as CSV</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>

      <Divider />
      {table ? (
        rowData ? (
          <Table
            rowData={rowData}
            columnDefs={columnDefs}
            // gridOptions={gridOptions}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
          />
        ) : (
          <Nodata />
        )
      ) : (
        <TableSkeleton />
      )}
    </div>
  );
}
