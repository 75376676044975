import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

export default function MappingSkeleton() {
  return (
    <Box
      sx={{
        width: "90%",
        ml: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width={"100%"} height={60} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width={"100%"} height={60} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width={"100%"} height={60} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width={"100%"} height={60} />
        </Grid>
      </Grid>
    </Box>
  );
}
