import React, { useEffect, useState } from "react";

import Table from "../../components/Table";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { formatDateRange, toRegulerCase } from "../../utils/Utils";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../utils/apiUtils";
import TableSkeleton from "../../components/TableSkeleton";

import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Nodata from "../../components/Nodata";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";
import image from "./images/Screenshot.png";

export default function CompetitorRate({ hotelId, hotelName }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
  };
  const editableStyle = {
    borderBottom: "1px dotted #18AD99",
    borderRadius: "3px",
    color: "#18AD99",
  };
  const [rowData, setRowData] = useState();

  const [columnDefs, setColumnDefs] = useState();

  const [refresh, setRefresh] = useState();
  const handlePostData = (modifiedBeRate, oldData) => {
    if (isNaN(modifiedBeRate)) {
      toast.error("Please enter the valid BE rate");
      return;
    }
    if (modifiedBeRate <= 0) {
      toast.error("Modified BE Rate should be greater than zero");
      return;
    }
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/gha-rate/update-gha-rate?hotelId=${hotelId}`,
      {
        id: oldData?.id,
        date: oldData?.date,
        hotelId: oldData?.hotel_id,
        dateEpoch: oldData?.date_epoch,
        roomTypeId: oldData?.room_type_id,
        roomType: oldData?.room_type,
        ratePlanId: oldData?.rate_plan_id,
        ratePlan: oldData?.rate_plan,
        bcomRate: oldData?.bcom_rate,
        agodaRate: oldData?.agoda_rate,
        mmtRate: oldData?.mmt_rate,
        prevBeRate: oldData?.prev_be_rate,
        modifiedBeRate: modifiedBeRate,
        lastModifiedTime: oldData?.last_modified_time,
      }
    )
      .then((res) => {
        setRefresh(new Date());
        toast.success("Successfully updated BE rate");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  const handleDate = (inputDate) => {
    const parts = inputDate.split(" ");
    const datePart = parts[0].split("-").reverse().join("-");
    const timePart = parts[1];
    const isoFormattedDate = datePart + "T" + timePart;

    const timestamp = Date.parse(isoFormattedDate);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const Currenttimestamp = currentDate.getTime();
    return timestamp - Currenttimestamp;
  };

  const onCellValueChanged = (params) => {
    handlePostData(params?.newValue, params?.data);
  };
  function customCellRendererBE(params) {
    if (handleDate(params?.data?.date) >= 0) {
      if (params.value <= 0) {
        return <span style={editableStyle}>NA</span>;
      } else {
        return <span style={editableStyle}>{params.value}</span>;
      }
    } else {
      if (params.value <= 0) {
        return "NA";
      } else {
        return params.value;
      }
    }
  }
  function customCellRenderer(params) {
    if (params.value <= 0) {
      return "NA";
    } else {
      return params.value;
    }
  }
  const handleColumn = (data) => {
    if (data?.length > 0) {
      const culumnData = Object.keys(data[0]).map((elm) => {
        if (elm === "modified_be_rate") {
          return {
            field: elm,
            headerName: toRegulerCase(elm),
            editable: (params) => {
              return handleDate(params?.data?.date) >= 0;
            },
            onCellValueChanged: onCellValueChanged,
            cellRenderer: customCellRendererBE,
          };
        } else {
          return {
            field: elm,
            headerName: toRegulerCase(elm),
            cellRenderer: customCellRenderer,
          };
        }
      });
      setColumnDefs(culumnData);
    }
  };

  //-------------------
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleShowButton = () => {
    setTable(false);

    // console.log("dateRange", dateRange);
    handleSpringDataRequest(
      `core/api/v1/gha-rate/get-rate-data?hotelId=${hotelId}&startDate=${moment(
        dateRange[0].startDate
      ).format("DD-MM-YYYY")}&endDate=${moment(dateRange[0].endDate).format(
        "DD-MM-YYYY"
      )}`
    )
      .then((data) => {
        setTable(true);
        handleColumn(data);
        setRowData(data);
        // console.log("data", data);
      })
      .catch((err) => {
        setTable(true);
        // console.log("err", err);
      });
  };
  // useEffect(() => {
  //   handleShowButton();
  // }, [refresh]);
  //---------------Skeleton--------
  const [table, setTable] = useState(false);
  //open and close date picker
  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  //---------     -------       --enable disable rate Competitor----    ------         ---------------
  // const [ghaEnabling, setGhaEnabling] = useState(false);
  const [competitorStatus, setCompetitorStatus] = useState();
  const checkingCompetitorStatus = () => {
    handleSpringDataRequest(
      `core/api/v1/competitor-pricing-metadata/get-competitor-pricing-feature-status?hotelId=${hotelId}`
    )
      .then((data) => {
        setCompetitorStatus(data);
      })
      .catch(() => {});
  };

  const storeCompetitorData = () => {
    handleSpringDataRequest(
      `core/api/v1/rate-shopper/store-competitor-data-by-hotel?hotelId=${hotelId}&channel=BOOKING`
    )
      .then(() => {})
      .catch(() => {});
  };

  const handleEnableRateCompetitor = () => {
    const payload = {
      id: competitorStatus?.id || null,
      hotelId: hotelId,
      enabled: competitorStatus?.enabled === "YES" ? "NO" : "YES",
      mode: "MANUAL",
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/competitor-pricing-metadata/add-update-competitor-pricing-status`,
      payload
    )
      .then(() => {
        if (payload?.enabled === "YES") {
          // setGhaEnabling(true);
          storeCompetitorData();
          toast.success("Successfully enabled rate competitor.");
        } else {
          toast.success("Successfully disabled rate competitor.");
        }
        checkingCompetitorStatus();
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  };
  useEffect(() => {
    checkingCompetitorStatus();
  }, []);
  //-----------------------------Add / Update competitor ------------------------------------
  const [competitorData, setCompetitorData] = useState();
  const [competitorModal, setCompetitorModal] = useState(false);

  const [id, setId] = useState();
  const [agodaHotelId, setAgodaHotelId] = useState("");
  const [bcomHotelId, setBcomHotelId] = useState("");
  const [bcomPageName, setBcomPageName] = useState("");
  const [agodaCityId, setAgodaCityId] = useState("");
  const [agodaRefererUrl, setAgodaRefererUrl] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mmtHotelId, setMmtHotelId] = useState("");
  const [mmtCityId, setMmtCityId] = useState("");
  const [mmtLat, setMmtLat] = useState("");
  const [mmtLgn, setMmtLgn] = useState("");
  const [ghaLoader, setGhaLoader] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  const assigningExistingData = () => {
    setId(competitorData?.id || null);
    setAgodaHotelId(competitorData?.agoda_hotel_id);
    setBcomHotelId(competitorData?.bcom_hotel_id);
    setBcomPageName(competitorData?.bcom_page_name);
    setAgodaCityId(competitorData?.agoda_city_id || -1);
    setAgodaRefererUrl(competitorData?.agoda_referer_url);
    setCountryCode(competitorData?.country_code);
    setMmtHotelId(competitorData?.mmt_hotel_id);
    setMmtCityId(competitorData?.mmt_city_id);
    setMmtLat(competitorData?.mmt_lat);
    setMmtLgn(competitorData?.mmt_lgn);
  };
  useEffect(() => {
    assigningExistingData();
  }, [competitorData]);

  const gettingRateCompetitorData = () => {
    handleSpringDataRequest(
      `core/api/v1/competitor-pricing-metadata/get-hotel-metadata?hotelId=${hotelId}`
    )
      .then((data) => {
        setCompetitorData(data);
      })
      .catch(() => {});
  };
  const updatingcompetitorData = () => {
    setGhaLoader(true);
    const payload = {
      id,
      hotelId,
      hotelName,
      agodaHotelId,
      bcomHotelId,
      bcomPageName,
      agodaRefererUrl,
      countryCode,
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/competitor-pricing-metadata/add-update-metadata`,
      payload
    )
      .then((msg) => {
        toast.success(
          `Successfully ${competitorData ? "updated" : "added"} Rate Competitor`
        );
        setCompetitorModal(false);
        gettingRateCompetitorData();
        setGhaLoader(false);
      })
      .catch((err) => {
        toast.error(err);
        setGhaLoader(false);
      });
  };
  useEffect(() => {
    gettingRateCompetitorData();
  }, []);

  const handleGHAModal = () => {
    setCompetitorModal(true);
  };
  return (
    <Box>
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader
          title={"Competitor heading"}
          subheader={"Competitor description"}
        >
          <Divider />
        </CardHeader>
        <Divider />
        <CardContent>
          {/* {ghaEnabling && (
            <Alert severity="info">
              You need to wait for the next 24 hr for data synchronization.
            </Alert>
          )} */}
          <Box sx={{ display: "flex", marginBottom: 1 }}>
            {/* {ghaStatus && (
              <>
                <Stack spacing={4} className="position-relative" sx={{ mt: 1 }}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box sx={{ position: "relative" }}>
                      <Button
                        variant="outlined"
                        onClick={handleClick}
                        fullWidth
                      >
                        {moment(dateRange[0].startDate).format("DD MMM YYYY")}{" "}
                        {" to "}
                        {moment(dateRange[0].endDate).format("DD MMM YYYY")}
                      </Button>
                      {open ? (
                        <Box sx={styles}>
                          <Stack spacing={3}>
                            <DateRange
                              editableDateInputs={true}
                              onChange={(item) =>
                                setDateRange([item.selection])
                              }
                              moveRangeOnFirstSelection={false}
                              ranges={dateRange}
                            />
                            <Stack
                              className="position-relative w-100 d-flex justify-content-between"
                              direction="row"
                              sx={{ justifyContent: "space-between" }}
                            >
                              <Button
                                variant="outlined"
                                onClick={() => setOpen(false)}
                                color="error"
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="custom-button"
                                onClick={() => {
                                  handleShowButton();
                                  handleClickAway();
                                  // console.log(dateRange);
                                }}
                              >
                                Apply
                              </Button>
                            </Stack>
                          </Stack>
                        </Box>
                      ) : null}
                    </Box>
                  </ClickAwayListener>
                </Stack>
                <Button
                  variant="custom-button"
                  sx={{ mb: 1, mt: 1, ml: 2 }}
                  onClick={handleShowButton}
                >
                  Show Data
                </Button>
              </>
            )} */}
            <Button
              variant="outlined"
              color={competitorStatus?.enabled === "YES" ? "error" : "primary"}
              sx={{ mb: 1, mt: 1, ml: 2 }}
              onClick={handleEnableRateCompetitor}
            >
              {competitorStatus?.enabled === "YES" ? "Disable" : "Enable"} Room
              type mapping
            </Button>

            {competitorStatus?.enabled === "YES" && (
              <Button
                variant="outlined"
                // color={ghaStatus?.enabled === "YES" ? "error" : "primary"}
                sx={{ mb: 1, mt: 1, ml: 2 }}
                onClick={handleGHAModal}
              >
                {competitorData ? "Update" : "Add"} hotel meta data
              </Button>
            )}
          </Box>

          {/* {table ? (
            rowData ? (
              ghaStatus && <Table rowData={rowData} columnDefs={columnDefs} />
            ) : (
              ghaStatus && <Nodata />
            )
          ) : (
            <TableSkeleton />
          )} */}
        </CardContent>
      </Card>

      <Modal
        open={competitorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5">
            {competitorData ? "Update" : "Add"} hotel meta data
          </Typography>
          <Divider light sx={{ mb: 2, mt: 1 }} />
          {/* <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="Hotel Name"
            variant="outlined"
            sx={{ m: 1 }}
            value={hotelName}
            onChange={(e) => setHotelName(e.target.value)}
          /> */}
          {/* <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="Agoda Hotel Id"
            variant="outlined"
            sx={{ m: 1 }}
            value={agodaHotelId}
            onChange={(e) => setAgodaHotelId(e.target.value)}
          /> */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              size="small"
              id="outlined-basic"
              label="BCOM Hotel Id"
              variant="outlined"
              sx={{ m: 1, width: "470px" }}
              value={bcomHotelId}
              onChange={(e) => setBcomHotelId(e.target.value)}
            />
            <IconButton onClick={() => setOpenInfo(true)}>
              <InfoIcon />
            </IconButton>
          </Box>
          <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="BCOM Page Name"
            variant="outlined"
            sx={{ m: 1, width: "470px" }}
            value={bcomPageName}
            onChange={(e) => setBcomPageName(e.target.value)}
          />
          {/* <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="Agoda City Id"
            variant="outlined"
            sx={{ m: 1 }}
            value={agodaCityId}
            onChange={(e) => setAgodaCityId(e.target.value)}
          /> */}
          {/* <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="Agoda Referer Url"
            variant="outlined"
            sx={{ m: 1 }}
            value={agodaRefererUrl}
            onChange={(e) => setAgodaRefererUrl(e.target.value)}
          /> */}
          {/* <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="Country Code"
            variant="outlined"
            sx={{ m: 1 }}
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
          /> */}

          {/* <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="MMT hotel id"
            variant="outlined"
            sx={{ m: 1 }}
            value={mmtHotelId}
            onChange={(e) => setMmtHotelId(e.target.value)}
          /> */}
          {/* <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="MMT city id"
            variant="outlined"
            sx={{ m: 1 }}
            value={mmtCityId}
            onChange={(e) => setMmtCityId(e.target.value)}
          /> */}
          {/* <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="MMT lat"
            variant="outlined"
            sx={{ m: 1 }}
            value={mmtLat}
            onChange={(e) => setMmtLat(e.target.value)}
          /> */}
          {/* <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="MMT lgn"
            variant="outlined"
            sx={{ m: 1 }}
            value={mmtLgn}
            onChange={(e) => setMmtLgn(e.target.value)}
          /> */}
          <Divider light sx={{ mt: 3 }} />
          <Button
            variant="outlined"
            color="error"
            sx={{ m: 1 }}
            onClick={() => setCompetitorModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="custom-button"
            sx={{ m: 1 }}
            onClick={updatingcompetitorData}
            disabled={ghaLoader}
          >
            Submit {ghaLoader && <CircularProgress size={24} />}
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openInfo}
        onClose={() => setOpenInfo(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { background: "#ccc", width: "864px" }]}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "800", cursor: "pointer" }}
            align="right"
            onClick={() => setOpenInfo(false)}
          >
            X
          </Typography>
          <Divider light sx={{ mb: 2, mt: 1 }} />
          <img src={image} width={800} style={{ borderRadius: "8px" }} />
          <Typography sx={{ fontWeight: "600", fontSize: "1.2rem", mt: 4 }}>
            Go to respective property on booking.com and from URL copy hotel
            name, highlighted above for reference. Do not take / and . while
            copying, only copy property name after / and before dot.
          </Typography>

          {/* <Divider light sx={{ mt: 3 }} />
          <Button
            variant="outlined"
            color="error"
            sx={{ m: 1 }}
            onClick={() => setOpenInfo(false)}
          >
            Close
          </Button> */}
        </Box>
      </Modal>
    </Box>
  );
}
