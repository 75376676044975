import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../utils/apiUtils";
import CircularProgress from "@mui/material/CircularProgress";

export default function RawmappingPage({ hide, hotelId }) {
  const [channels, setChannels] = useState();
  const [channelName, setChannelName] = useState("");
  const [channelId, setChannelId] = useState("");
  const [channeHotelId, setChannelHotelId] = useState("");
  const [tempHotelChannelId, setTempHotelChannelId] = useState("");
  const [pmsData, setPmsData] = useState();
  const [otaData, setOtaData] = useState();
  const [refresh, setRefresh] = useState();
  const [findLoader, setFindLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSaveButton = () => {
    setSubmitLoader(true);
    // pmsData.channelHotels[channelName] = channeHotelId;
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/cm/apply-hotel-channel-config/?hotel_id=${hotelId}&hotelId=${hotelId}`,
      pmsData
    )
      .then((res) => {
        setSubmitLoader(false);
        if (res.code === "200") {
          toast.success("Channel Successfully Mapped");
          hide();
        } else {
          toast.error(res?.message);
        }
      })
      .catch(() => {
        setSubmitLoader(false);
        toast.error("Something went wrong");
      });
  };

  const gettingChannelsList = () => {
    handleSpringDataRequest(
      `core/api/v1/cm/get-all-channels?hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((res) => {
        setChannels(
          res.sort(function (a, b) {
            return a.channelName.localeCompare(b.channelName);
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const handleChangeChannel = (e) => {
    setChannelHotelId("");
    setTempHotelChannelId("");

    setChannelId(e);
    const channelName = channels.filter((channel) => channel.channelId === e)[0]
      .channelName;
    setChannelName(channelName);
  };

  const handleHotelChanelIdTextField = (e) => {
    setTempHotelChannelId(e);
  };
  const handleIfPMSdataAvailable = (data) => {
    setPmsData(data);
    setChannelHotelId(data.channelHotels[channelName].split("-")[0]);
    setTempHotelChannelId(data.channelHotels[channelName].split("-")[0]);
  };
  const handleIfOtaDataAvailable = (data) => {
    setOtaData(data);
  };
  const handleIfOtaDataNotAvailable = () => {
    toast.error("Please enter the valid channel hotel id");
    setOtaData("");
  };
  const getPmsHoteldata = () => {
    setOtaData("");
    handleSpringDataRequest(
      `core/api/v1/cm/get-hotel-channel-config?channels=${channelId}&hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((data) =>
        data?.roomTypes ? handleIfPMSdataAvailable(data) : setPmsData("")
      )
      .catch((err) => setPmsData(""));
  };

  const getOtaData = () => {
    setFindLoader(true);
    handleSpringDataRequest(
      `core/api/v1/cm/get-product-info?channelHotelId=${channeHotelId}&channelName=${channelName}&hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((data) => {
        setFindLoader(false);
        data?.roomTypeList
          ? handleIfOtaDataAvailable(data)
          : handleIfOtaDataNotAvailable();
      })
      .catch((err) => {
        setFindLoader(false);
        setOtaData("");
        toast.error("Something went wrong");
      });
  };

  const handleFindButton = () => {
    setChannelHotelId(tempHotelChannelId);
    setRefresh(new Date());
  };

  useEffect(() => {
    channelId && channelName && getPmsHoteldata();
  }, [channelId]);

  useEffect(() => {
    channeHotelId && channelName && getOtaData();
  }, [channeHotelId, channelName, refresh]);
  //------------Getting Channels List----------
  useEffect(() => {
    gettingChannelsList();
  }, []);

  return (
    <Box sx={{ mt: 7 }}>
      <Box>
        <FormControl sx={{ minWidth: 200, ml: 2 }}>
          <InputLabel id="demo-simple-select-label">Channel</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={channelId}
            label="Channel"
            onChange={(e) => handleChangeChannel(e.target.value)}
          >
            {channels?.map((channel) => (
              <MenuItem value={channel.channelId}>
                {channel.channelName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ ml: 2 }}
          size="small"
          id="outlined-basic"
          label="Channel Hotel Id"
          variant="outlined"
          name="ChannelHotelId"
          value={tempHotelChannelId} //---Need to work
          // disabled={buttonDisable}
          onChange={(e) => handleHotelChanelIdTextField(e.target.value.trim())}
        />

        <Button
          sx={{ ml: 2 }}
          onClick={handleFindButton}
          // disabled={buttonDisable}
          variant="custom-button"
          // size="small"
        >
          {findLoader ? <CircularProgress size={20} color="inherit" /> : "Find"}
        </Button>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ListItem sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">OTA Data</Typography>

            <TextareaAutosize
              style={{ width: "100%" }}
              value={JSON.stringify(otaData, null, "\t")}
              maxRows={36}
              minRows={36}
            />
          </ListItem>
        </Grid>
        <Grid item xs={6}>
          <ListItem sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">PMS Data</Typography>
            <TextareaAutosize
              style={{ width: "100%" }}
              value={JSON.stringify(pmsData, null, "\t")}
              maxRows={36}
              minRows={36}
              onChange={(e) => {
                console.log(pmsData);
                setPmsData(JSON.parse(e.target.value));
              }}
            />
          </ListItem>
        </Grid>
        <Grid item xs={11}></Grid>
        <Grid item xs={1}>
          {pmsData && (
            <Button
              onClick={handleSaveButton}
              variant="custom-button"
              sx={{ marginLeft: "15px" }}
            >
              {submitLoader ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
