import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { handleSpringDataMutationRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { nextTenYearOld } from "../../utils/common/Utilities";

export default function AddSubscriptions({ handleClose, hotelId, setRefresh }) {
  const [packageName, setPackageName] = useState("");
  const [chargeAmount, setChargeAmount] = useState("");
  const [every, setEvery] = useState("");
  const [period, setPeriod] = useState("");
  const [startDate, setStartDate] = useState(
    new Date()?.toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState({});
  const [inactiveDate, setInactiveDate] = useState({});
  const [propertyStatus, setPropertyStatus] = useState("");
  const [walkInCommission, setWalkInCommission] = useState(0);
  const [bookingEngineCommission, setBookingEngineCommission] = useState(5);
  const [directOTAandGHACommission, setDirectOTAandGHACommission] =
    useState(10);
  const [externalOTACommission, setExternalOTACommission] = useState(0);
  const [flexCollectMode, setFlexCollectMode] = useState("AUTO");
  const [flexCollectManualComm, setFlexCollectManualComm] = useState(4.72);
  const [flexCollectAutoComm, setFlexCollectAutoComm] = useState(4.72);
  const [invoicePaymentComm, setInvoicePaymentComm] = useState(3.5);
  const [paymentLinkComm, setPaymentLinkComm] = useState(3.5);
  const [roomnightfee, setRoomnightfee] = useState(0);
  const [magiclinkSelfcheckinComm, setMagiclinkSelfcheckinComm] = useState(5);
  const [magiclinkEarlycheckinComm, setMagiclinkEarlycheckinComm] = useState(5);
  const [magiclinkLatecheckoutComm, setMagiclinkLatecheckoutComm] = useState(5);
  const [magiclinkPOSComm, setMagiclinkPOSComm] = useState(5);
  const [magiclinkSelfupgradeComm, setMagiclinkSelfupgradeComm] = useState(5);
  const [propertyContact, setPropertyContact] = useState("");
  const [propertyStatusNotes, setPropertyStatusNotes] = useState(" ");
  const [dealOwner, setDealOwner] = useState("");
  const [isDigitalFranchise, setIsDigitalFranchise] = useState("NO");
  const [flexCollectNotes, setFlexCollectNotes] = useState(" ");
  const [sMSNotifications, setSMSNotifications] = useState("DISABLE");
  const [whatsAppNotifications, setWhatsAppNotifications] = useState("DISABLE");
  const [iDScannerIntegration, setIDScannerIntegration] = useState("DISABLE");
  const [enableonGHA, setEnableonGHA] = useState("ENABLED");
  const [enableonBOOKFLEXI, setEnableonBOOKFLEXI] = useState("DISABLED");
  const [AccountType, setAccountType] = useState("");
  const [pgTransferOn, setPgTransferOn] = useState("");
  const [mobileAppQrCodeCommission, setMobileAppQrCodeCommission] = useState(0);

  const logedUserEmail = localStorage.getItem("logedUserEmail");
  const accessibleEmails = ["sasank.talasila@stayflexi.com"];

  useEffect(() => {
    if (propertyStatus === "INACTIVE") {
      setInactiveDate(nextTenYearOld());
    } else {
      setInactiveDate({});
    }
  }, [propertyStatus]);

  const handleFlexCollectMode = (e) => {
    if (e.target.value === "DISABLED") {
      setFlexCollectManualComm(0);
    }
    setFlexCollectMode(e.target.value);
  };

  const handleAddSubscription = () => {
    if (!chargeAmount && chargeAmount !== 0) {
      toast.error("Charged amount is required");
      return;
    }
    if (!startDate) {
      toast.error("Start date is required");
      return;
    }
    if (!propertyStatus) {
      toast.error("Property status is required");
      return;
    }

    // if (
    //   bookingEngineCommission < 5 &&
    //   !accessibleEmails?.includes(logedUserEmail)
    // ) {
    //   toast.error(
    //     "Booking engine Commission should be greater than or equal to 5%"
    //   );
    //   return;
    // }

    if (
      flexCollectManualComm < 3.5 &&
      flexCollectMode !== "DISABLED" &&
      !accessibleEmails?.includes(logedUserEmail)
    ) {
      toast.error(
        "FlexCollect Manual Commission % should be greater than or equal to 3.5"
      );
      return;
    }
    if (
      flexCollectAutoComm < 3.5 &&
      flexCollectMode !== "DISABLED" &&
      !accessibleEmails?.includes(logedUserEmail)
    ) {
      toast.error(
        "FlexCollect Auto Commission % should be greater than or equal to 3.5"
      );
      return;
    }
    if (
      invoicePaymentComm < 3.5 &&
      !accessibleEmails?.includes(logedUserEmail)
    ) {
      toast.error(
        "Invoice Payment Commission % should be greater than or equal to 3.5"
      );
      return;
    }
    if (paymentLinkComm < 3.5 && !accessibleEmails?.includes(logedUserEmail)) {
      toast.error(
        "Payment Link Commission % should be greater than or equal to 3.5"
      );
      return;
    }
    if (
      (magiclinkSelfcheckinComm < 3.5 ||
        magiclinkEarlycheckinComm < 3.5 ||
        magiclinkLatecheckoutComm < 3.5 ||
        magiclinkPOSComm < 3.5 ||
        magiclinkSelfupgradeComm < 3.5) &&
      !accessibleEmails?.includes(logedUserEmail)
    ) {
      toast.error(
        "Magic Link Commission % should be greater than or equal to 3.5"
      );
      return;
    }

    setLoader(true);
    const payload = {
      name: packageName,
      amount: chargeAmount,
      interval: 1,
      period: period,
      sub_startdate: startDate,
      sub_enddate: endDate,
      inactive_date: inactiveDate,
      property_status: propertyStatus,
      perc_direct: walkInCommission,
      perc_onlinedirect: bookingEngineCommission,
      perc_onlinemarket: directOTAandGHACommission,
      perc_extota: externalOTACommission,
      pss_mode: flexCollectMode,
      pss_manual_commission: flexCollectManualComm,
      pss_automatic_commission: flexCollectAutoComm,
      invoice_payment_commission: invoicePaymentComm,
      payment_link_commission: paymentLinkComm,
      room_night_fee: roomnightfee,
      self_service_scin_commission: magiclinkSelfcheckinComm,
      self_service_ecin_commission: magiclinkEarlycheckinComm,
      self_service_lcout_commission: magiclinkLatecheckoutComm,
      self_service_pos_commission: magiclinkPOSComm,
      self_service_room_upgrade_commission: magiclinkSelfupgradeComm,
      mobile_app_qr_code_commission: +mobileAppQrCodeCommission,
      property_contact: propertyContact,
      property_status_log: propertyStatusNotes,
      deal_owner: dealOwner,
      is_digitalfranchise: isDigitalFranchise,
      pss_comments: flexCollectNotes,

      booking_sms_notif: whatsAppNotifications,
      booking_whatsapp_notif: whatsAppNotifications,
      enable_id_scanner: iDScannerIntegration,

      enable_gha: enableonGHA,
      enable_bookflexi: enableonBOOKFLEXI,
      hotel_id: hotelId,
      pg_account_type: AccountType,
      pg_transfer_on: pgTransferOn,
    };
    handleSpringDataMutationRequest(
      "POST",
      "api/v2/metaAdmin/metaAdminAddHotelSubscription/",
      payload
    )
      .then((data) => {
        setLoader(false);
        setRefresh(new Date());
        handleClose();
        toast.success("Subscription added successfully");
        console.log(data);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  //------------------Loader---------------------
  const [loader, setLoader] = useState(false);
  return (
    <Box sx={{ p: 1 }}>
      <Card>
        <CardContent>
          <Typography>Basic Info</Typography>
          <Divider sx={{ mb: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Package Name
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={packageName}
                  label="Package Name"
                  onChange={(e) => setPackageName(e.target.value)}
                >
                  <MenuItem value={"All in one platform"}>
                    All in one platform
                  </MenuItem>
                  <MenuItem value={"Empower"}>Empower</MenuItem>
                  <MenuItem value={"Starter"}>Starter</MenuItem>
                  <MenuItem value={"Pro"}>Pro</MenuItem>
                  <MenuItem value={"Flex"}>Flex</MenuItem>
                  <MenuItem value={"Custom"}>Custom</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Charge Amount"
                id="fullWidth"
                variant="outlined"
                value={chargeAmount}
                onChange={(e) => setChargeAmount(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Every"
                id="fullWidth"
                variant="outlined"
                value={every}
                onChange={(e) => setEvery(e.target.value)}
              />
            </Grid> */}

            {/* <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Period</InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={period}
                  label="Period"
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <MenuItem value="day">Day(s)</MenuItem>
                  <MenuItem value="week">Week(s)</MenuItem>
                  <MenuItem value="month">Month(s)</MenuItem>
                  <MenuItem value="year">Year(s)</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={3}>
              <TextField
                label="Start Date *"
                type="date"
                size="small"
                fullWidth
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="End Date *"
                type="date"
                size="small"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Inactive Date *"
                type="date"
                size="small"
                fullWidth
                value={inactiveDate}
                onChange={(e) => setInactiveDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Property Status
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={propertyStatus}
                  label="Property Status"
                  onChange={(e) => setPropertyStatus(e.target.value)}
                >
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">Inactive</MenuItem>
                  <MenuItem value="TEST">Test</MenuItem>
                  <MenuItem value="INVALID">Invalid</MenuItem>
                  <MenuItem value="TRIAL">Trial</MenuItem>
                  <MenuItem value="INQUIRY">Inquiry</MenuItem>
                  <MenuItem value="ONBOARDING">Onboarding in progress</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Typography>Commissions %</Typography>
          <Divider sx={{ mb: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Walk-In Commission %"
                id="fullWidth"
                variant="outlined"
                value={walkInCommission}
                onChange={(e) => setWalkInCommission(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Booking Engine Commission %"
                id="fullWidth"
                variant="outlined"
                value={bookingEngineCommission}
                onChange={(e) => setBookingEngineCommission(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Direct OTA and GHA Commission %"
                id="fullWidth"
                variant="outlined"
                value={directOTAandGHACommission}
                onChange={(e) => setDirectOTAandGHACommission(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="External OTA Commission %"
                id="fullWidth"
                variant="outlined"
                value={externalOTACommission}
                onChange={(e) => setExternalOTACommission(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Flex Collect Mode
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={flexCollectMode}
                  label="Flex Collect Mode"
                  onChange={handleFlexCollectMode}
                >
                  <MenuItem value="DISABLED">Disabled</MenuItem>
                  <MenuItem value="AUTO">Auto</MenuItem>
                  <MenuItem value="ENABLED">Enabled</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="FlexCollect Manual Commission %"
                id="fullWidth"
                variant="outlined"
                disabled={flexCollectMode === "DISABLED"}
                value={flexCollectManualComm}
                onChange={(e) => setFlexCollectManualComm(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="FlexCollect Auto Commission %                "
                id="fullWidth"
                variant="outlined"
                disabled={flexCollectMode === "DISABLED"}
                value={flexCollectAutoComm}
                onChange={(e) => setFlexCollectAutoComm(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Invoice Payment Commission %
                "
                id="fullWidth"
                variant="outlined"
                value={invoicePaymentComm}
                onChange={(e) => setInvoicePaymentComm(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Payment Link Commission %
                "
                id="fullWidth"
                variant="outlined"
                value={paymentLinkComm}
                onChange={(e) => setPaymentLinkComm(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Room night fee
                "
                id="fullWidth"
                variant="outlined"
                value={roomnightfee}
                onChange={(e) => setRoomnightfee(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Magiclink Selfcheckin Commission%
                "
                id="fullWidth"
                variant="outlined"
                value={magiclinkSelfcheckinComm}
                onChange={(e) => setMagiclinkSelfcheckinComm(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Magiclink Earlycheckin Commission%
                "
                id="fullWidth"
                variant="outlined"
                value={magiclinkEarlycheckinComm}
                onChange={(e) => setMagiclinkEarlycheckinComm(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Magiclink Latecheckout Commission%
                "
                id="fullWidth"
                variant="outlined"
                value={magiclinkLatecheckoutComm}
                onChange={(e) => setMagiclinkLatecheckoutComm(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Magiclink POS Commission%
                "
                id="fullWidth"
                variant="outlined"
                value={magiclinkPOSComm}
                onChange={(e) => setMagiclinkPOSComm(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Magiclink Selfupgrade Commission%
                "
                id="fullWidth"
                variant="outlined"
                value={magiclinkSelfupgradeComm}
                onChange={(e) => setMagiclinkSelfupgradeComm(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Mobile App QR Code Commission%
                "
                id="fullWidth"
                variant="outlined"
                value={mobileAppQrCodeCommission}
                onChange={(e) => setMobileAppQrCodeCommission(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Typography>Others</Typography>
          <Divider sx={{ mb: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Property Contact
                "
                id="fullWidth"
                variant="outlined"
                value={propertyContact}
                onChange={(e) => setPropertyContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Property Status Notes
                "
                id="fullWidth"
                variant="outlined"
                value={propertyStatusNotes}
                onChange={(e) => setPropertyStatusNotes(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Deal Owner
                "
                id="fullWidth"
                variant="outlined"
                value={dealOwner}
                onChange={(e) => setDealOwner(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Is Digital Franchise
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isDigitalFranchise}
                  label="Is Digital Franchise
                    "
                  onChange={(e) => setIsDigitalFranchise(e.target.value)}
                >
                  <MenuItem value="YES">YES</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                label="Flex Collect Notes
                "
                id="fullWidth"
                variant="outlined"
                value={flexCollectNotes}
                onChange={(e) => setFlexCollectNotes(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  SMS Notifications
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sMSNotifications}
                  label="SMS Notifications"
                  onChange={(e) => setSMSNotifications(e.target.value)}
                >
                  <MenuItem value="DISABLE">DISABLE</MenuItem>
                  <MenuItem value="ENABLE">ENABLE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  WhatsApp Notifications
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={whatsAppNotifications}
                  label="WhatsApp Notifications
                    "
                  onChange={(e) => setWhatsAppNotifications(e.target.value)}
                >
                  <MenuItem value="DISABLE">DISABLE</MenuItem>
                  <MenuItem value="ENABLE">ENABLE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  ID Scanner Integration
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={iDScannerIntegration}
                  label="ID Scanner Integration
                    "
                  onChange={(e) => setIDScannerIntegration(e.target.value)}
                >
                  <MenuItem value="DISABLE">DISABLE</MenuItem>
                  <MenuItem value="ENABLE">ENABLE</MenuItem>
                  <MenuItem value="AMBIRSCAN">AMBIRSCAN</MenuItem>
                  <MenuItem value="IDGURU">IDGURU</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Enable on GHA
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={enableonGHA}
                  label="Enable on GHA
                    "
                  onChange={(e) => setEnableonGHA(e.target.value)}
                >
                  <MenuItem value="DISABLED">DISABLED</MenuItem>
                  <MenuItem value="ENABLED">ENABLED</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Enable on BOOKFLEXI
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={enableonBOOKFLEXI}
                  label="Enable on BOOKFLEXI
                    "
                  onChange={(e) => setEnableonBOOKFLEXI(e.target.value)}
                >
                  <MenuItem value="DISABLED">DISABLED</MenuItem>
                  <MenuItem value="ENABLED">ENABLED</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Account type
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={AccountType}
                  label="Account type"
                  onChange={(e) => setAccountType(e.target.value)}
                >
                  <MenuItem value="DIRECT_CHARGE">Direct Charge</MenuItem>
                  <MenuItem value="SEPARATE_CHARGE">Separate Charge</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  PG Transfer on
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pgTransferOn}
                  label="PG Transfer on"
                  onChange={(e) => setPgTransferOn(e.target.value)}
                >
                  <MenuItem value="IMMEDIATE">Immediately</MenuItem>
                  <MenuItem value="BOOKING_CHECKIN">
                    At booking checkin
                  </MenuItem>
                  <MenuItem value="BOOKING_CHECKOUT">
                    At booking checkout
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Button
        color="error"
        variant="outlined"
        sx={{ m: 2 }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        variant="custom-button"
        sx={{ m: 2 }}
        onClick={handleAddSubscription}
      >
        {loader ? <CircularProgress size={24} /> : "Add Subscription"}
      </Button>
    </Box>
  );
}
