import React, { useEffect, useState } from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  FormControl,
  ClickAwayListener,
  Button,
} from "@mui/material";

import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

import { handleDjangoDataRequest } from "../../../utils/apiUtils";

import { sideBar } from "../../../utils/common/sidebar.js";
import CustomDateComponent from "./customDateComponent";
import "./acc.css";
import { DateRange } from "react-date-range";

export default function AccountPayments({ groupId, res }) {
  const [currency, setCurrency] = useState("");
  const columnsInr = [
    {
      field: "hotel_id",
      headerName: "Hotel id",
    },
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "amount",
      headerName: `Amount (${currency})`,
      cellRenderer: (params) => {
        return currency + " " + parseFloat(params.value || 0).toFixed(2);
      },
    },
    {
      field: "transfer_amount",
      headerName: `Transfer amount  (${currency})`,
      cellRenderer: (params) => {
        return currency + " " + parseFloat(params.value || 0).toFixed(2);
      },
    },

    {
      field: "pg_fee",
      headerName: `PG fee  (${currency})`,
      cellRenderer: (params) => {
        return currency + " " + parseFloat(params.value || 0).toFixed(2);
      },
    },

    {
      field: "sf_commission",
      headerName: `SF commission  (${currency})`,
      cellRenderer: (params) => {
        return currency + " " + parseFloat(params.value || 0).toFixed(2);
      },
    },
    {
      field: "payment_source",
      headerName: "Payment source ",
    },
    {
      field: "date",
      headerName: "Date",
      valueFormatter: (params) => {
        return moment(params.value).format("ll");
      },
    },
    {
      field: "booking_id",
      headerName: "Booking id",
    },
    {
      field: "booking_source",
      headerName: "Booking source",
    },

    {
      field: "emailid",
      headerName: "Email id",
    },
    {
      field: "pg_id",
      headerName: "PG id",
    },
  ];

  const [rowsData, setRowsData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);
  const [selectedRows, setSelectedRows] = useState(null);
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState([
    {
      hotel_id: "Total",
      amount: 0,
      transfer_amount: 0,
      pg_fee: 0,
      sf_commission: 0,
    },
  ]);
  useEffect(() => {
    // console.log("accountInfo", res?.account_details?.billing_address_country);
    if (res?.account_details?.billing_address_country === "India") {
      setCurrency("Rs.");
    } else {
      setCurrency("$");
    }
  }, [res]);
  //Total---------------------------

  const handelingPinnedData = () => {
    console.log("rowsData", rowsData);
    if (rowsData?.length > 0) {
      let amount = 0;
      let transfer_amount = 0;
      let pg_fee = 0;
      let sf_commission = 0;
      rowsData.map((elm) => {
        amount = amount + (elm?.amount || 0);
        transfer_amount = transfer_amount + (elm?.transfer_amount || 0);
        pg_fee = pg_fee + (elm?.pg_fee || 0);
        sf_commission = sf_commission + (elm?.sf_commission || 0);
      });
      setPinnedBottomRowData([
        {
          hotel_id: "Total",
          amount: amount,
          transfer_amount: transfer_amount,
          pg_fee: pg_fee,
          sf_commission: sf_commission,
        },
      ]);
    } else {
      setPinnedBottomRowData([
        {
          hotel_id: "Total",
          amount: 0,
          transfer_amount: 0,
          pg_fee: 0,
          sf_commission: 0,
        },
      ]);
    }
  };
  useEffect(() => {
    handelingPinnedData();
  }, [rowsData]);

  useEffect(() => {
    if (gridApi) {
      // Set the pinned bottom row data
      gridApi.setPinnedBottomRowData(pinnedBottomRowData);
    }
  }, [gridApi, pinnedBottomRowData]);

  const handleFilterRows = (filteredRows) => {
    if (filteredRows?.length > 0) {
      let amount = 0;
      let transfer_amount = 0;
      let pg_fee = 0;
      let sf_commission = 0;
      filteredRows.map((elm) => {
        amount = amount + (elm?.data?.amount || 0);
        transfer_amount = transfer_amount + (elm?.data?.transfer_amount || 0);
        pg_fee = pg_fee + (elm?.data?.pg_fee || 0);
        sf_commission = sf_commission + (elm?.data?.sf_commission || 0);
      });
      setPinnedBottomRowData([
        {
          hotel_id: "Total",
          amount: amount,
          transfer_amount: transfer_amount,
          pg_fee: pg_fee,
          sf_commission: sf_commission,
        },
      ]);
    } else {
      setPinnedBottomRowData([
        {
          hotel_id: "Total",
          amount: 0,
          transfer_amount: 0,
          pg_fee: 0,
          sf_commission: 0,
        },
      ]);
    }
  };
  const onFilterChanged = (params) => {
    const gridApi = params.api;
    const appliedFilterModel = gridApi.getFilterModel();
    const filteredRows = gridApi.getRenderedNodes();
    handleFilterRows(filteredRows);
    console.log("Filtered Rows:", filteredRows);
  };

  //-------------------------------------
  const onGridReady = (params) => {
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
    // setAllCols(params?.columnApi?.columnController?.columnDefs);
    // const mp = new Map();
    // const dt = params?.columnApi?.columnController?.columnDefs.map(
    //   (res, idx) => {
    //     mp.set(res.field, {
    //       header: res.headerName,
    //       hidden: false,
    //       type: "",
    //       order: idx + 1,
    //     });
    //   }
    // );
    // setColmap(mp);
  };
  const onExportClick = () => {
    selectedRowsLength === 0
      ? gridApi.exportDataAsCsv()
      : gridApi.exportDataAsCsv({ onlySelected: true });
  };
  const getDownloadType = (dType) => {
    // console.log("download type", dType);
    dType === "1" && onExportClick();
    // dType === "2" && onOpenModal();
    // dType === "3" && onExportExcel();
    // dType === "4" && onExportTally();
  };

  const defaultColDefs = {
    cellStyle: { fontSize: "11px" },
    sortable: true,
    floatingFilter: true,
    tooltipField: "name",
    filter: "agMultiColumnFilter",
    display: "subMenu",
    components: {
      agDateInput: CustomDateComponent,
    },
    resizable: true,
    cellRendererParams: {
      checkbox: true,
    },
    // flex: 1,
  };

  const onSelectionChanged = (event) => {
    setSelectedRowsLength(event.api.getSelectedRows().length);
    setSelectedRows(event.api.getSelectedRows());
    // const selectedBooking_Id = event.api.getSelectedRows()[0]?.bookingid;
    // setSelectedBookingId(selectedBooking_Id);
  };
  const rowSelectionType = "multiple";

  //open and close date picker
  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };
  //-------------------
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  function getReportDate(today) {
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const handelingRowData = () => {
    handleDjangoDataRequest(
      `api/v2/reports/getReportData/?report_type=DFStatsReport&start_date=${getReportDate(
        dateRange[0]?.startDate
      )}&end_date=${getReportDate(
        dateRange[0]?.endDate
      )}&group_email=${groupId}`
    ).then((data) => {
      setRowsData(data?.report_data);
    });
  };
  useEffect(() => {
    handelingRowData();
  }, []);

  const handleShowButton = () => {
    handelingRowData();
  };
  return (
    <div>
      <Stack className="agGridWrapr position-relative">
        <Stack
          spacing={2}
          className="agGridTableWrapper position-relative w-100"
        >
          <Stack
            direction="row"
            className="position-relative w-100 justify-content-between"
          >
            <Stack direction="row" spacing={2}>
              <Stack spacing={4} className="position-relative" sx={{ mt: 1 }}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box sx={{ position: "relative" }}>
                    <Button variant="outlined" onClick={handleClick} fullWidth>
                      {moment(dateRange[0].startDate).format("DD MMM YYYY")}{" "}
                      {" to "}
                      {moment(dateRange[0].endDate).format("DD MMM YYYY")}
                    </Button>
                    {open ? (
                      <Box sx={styles}>
                        <Stack spacing={3}>
                          <DateRange
                            editableDateInputs={true}
                            onChange={(item) => setDateRange([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange}
                          />
                          <Stack
                            className="position-relative w-100 d-flex justify-content-between"
                            direction="row"
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => setOpen(false)}
                              color="error"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="custom-button"
                              onClick={() => {
                                handleShowButton();
                                handleClickAway();
                                // console.log(dateRange);
                              }}
                            >
                              Apply
                            </Button>
                          </Stack>
                        </Stack>
                      </Box>
                    ) : null}
                  </Box>
                </ClickAwayListener>
              </Stack>
              {/* <TextField
                id="outlined-basic"
                onChange={onFilterTextChange}
                label="Search"
                size="small"
              /> */}
              {/* <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Pages</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gridApi?.paginationPageSize}
                    label="Pages"
                    onChange={(e) => onPaginationChange(e.target.value)}
                  >
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"20"}>20</MenuItem>
                    <MenuItem value={"50"}>50</MenuItem>
                    <MenuItem value={"100"}>100</MenuItem>
                  </Select>
                </FormControl>
              </Box> */}

              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Export as
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"0"}
                    label="Export as"
                    onChange={(e) => getDownloadType(e.target.value)}
                  >
                    <MenuItem value={"0"}>Select option</MenuItem>
                    <MenuItem value={"1"}>Export as CSV</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Stack>
          <Stack className="agTable">
            <Stack className="ag-theme-material" sx={{ height: "75vh" }}>
              <AgGridReact
                className="agGridTable"
                rowData={rowsData}
                onGridReady={onGridReady}
                columnDefs={columnsInr}
                defaultColDef={defaultColDefs}
                enableBrowserTooltips={true}
                onSelectionChanged={onSelectionChanged}
                enableMultiRowDragging={false}
                rowMultiSelectWithClick={false}
                paginationPageSize={50}
                alwaysShowBothConditions={true}
                rowSelection={rowSelectionType}
                suppressPivotMode={true}
                suppressColumnMove={true}
                suppressRowGroups={true}
                sideBar={sideBar()}
                groupMultiAutoColumn={true}
                statusBar={{
                  statusPanels: [
                    {
                      statusPanel: "agTotalAndFilteredRowCountComponent",
                      align: "left",
                    },
                    {
                      statusPanel: "agTotalRowCountComponent",
                      align: "center",
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" },
                  ],
                }}
                onFilterChanged={onFilterChanged}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
