import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Modal,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoModal from "./InfoModal";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  handleSpringDataMutationRequest,
  handleSpringDataMutationRequestCmBooking,
} from "../../../../utils/apiUtils";
import { toast } from "react-toastify";
import { channelLogoMapDash } from "../../../../utils/channelLogo";
import CircularProgress from "@mui/material/CircularProgress";

let timer;

export default function ChannelCard({ elm, setToRerender, hotelId }) {
  const [userName, setUserName] = useState();
  const [userPassword, setUserPassword] = useState();
  const [syncLoader, setSyncLoader] = useState(false);
  const [pullLoader, setPullLoader] = useState(false);

  const postData = () => {
    setLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/cm/apply-channel-creds/?hotel_id=${hotelId}&hotelId=${hotelId}`,
      {
        channelId: elm.channelId,
        password: userPassword,
        username: userName,
      }
    )
      .then((elm) => {
        toast.success("Successfully updated");
        setLoading(false);
        setOpen(false);
        setToRerender(new Date());
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoading(false);
        setOpen(false);
      });
  };

  // const handleInputClick = () => {
  //   timer && clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     postData();
  //   }, 3000);
  // };
  // useEffect(() => {
  //   handleInputClick();
  // }, [userName, userPassword]);

  const registerChannel = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/cm/register-channel-to-hotel/?hotel_id=${hotelId}&hotelId=${hotelId}`,
      {
        channelId: elm.channelId,
      }
    )
      .then((elm) => {
        setToRerender(new Date());
        toast.success(elm);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  const unRegisterChannel = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/cm/unregister-channel-from-hotel/?hotel_id=${hotelId}&hotelId=${hotelId}`,
      {
        channelId: elm.channelId,
      }
    )
      .then((elm) => {
        toast.success(elm);
        setToRerender(new Date());
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  const handleSwitchButton = () => {
    if (elm.status === "REGISTERED") {
      unRegisterChannel();
    } else {
      registerChannel();
    }
  };

  const handleSyncButton = () => {
    setSyncLoader(true);
    if (elm.status === "REGISTERED") {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/cm/sync-channels/?hotel_id=${hotelId}&hotelId=${hotelId}`,
        {
          channelIds: [elm.channelId],
        }
      )
        .then((elm) => {
          toast.success("Successfully Synced");
          setSyncLoader(false);
        })
        .catch((err) => {
          setSyncLoader(false);
          toast.error(err?.response?.data);
        });
    } else {
      toast.error("Can not Sync unregisterd Channel");
    }
  };

  const handlePullBookingButton = () => {
    setPullLoader(true);
    if (elm.status === "REGISTERED") {
      handleSpringDataMutationRequestCmBooking(
        "POST",
        `core/api/v1/cm/pull-bookings/?hotel_id=${hotelId}&hotelId=${hotelId}`,
        {
          channelIds: [elm.channelId],
        }
      )
        .then(() => {
          // setPullLoader(false);
          toast.success("Successfully Pulled");
        })
        .catch((err) => {
          // setPullLoader(false);
          toast.error(err?.response?.data);
        });
    } else {
      toast.error("Can not pull unregisterd Channel");
    }
  };
  //-----------
  useEffect(() => {
    setUserName(elm?.username);
    setUserPassword(elm?.password);
  }, [elm]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <Box sx={{ mb: 1 }}>
      <Card sx={{ minWidth: 275, position: "relative" }}>
        <CardContent>
          {channelLogoMapDash[elm.channelName.toUpperCase()] ? (
            <img
              height="20px"
              alt={elm.channelName}
              src={channelLogoMapDash[elm.channelName.toUpperCase()]}
            />
          ) : (
            <Typography sx={{ fontSize: 14 }} gutterBottom variant="h5">
              {elm.channelName}
            </Typography>
          )}
          {/* <IconButton>
            <ModeEditIcon />
          </IconButton> */}
          <Button
            variant="outlined"
            size="small"
            sx={{ ml: 2 }}
            onClick={() => setOpen(true)}
          >
            Update usename and password
          </Button>

          <Typography variant="body2">
            Username:
            <span>
              <input
                disabled
                type="text"
                value={elm?.username}
                style={{ border: "none", borderBottom: "dotted gray" }}
                // onChange={(e) => {
                //   setUserName(e.target.value);
                // }}
              />
              {/* <IconButton size="small">
                <ModeEditIcon fontSize="inherit" />
              </IconButton> */}
            </span>
            <br></br>
            Password:
            <span>
              <input
                disabled
                type="text"
                value={elm?.password}
                style={{ border: "none", borderBottom: "dotted gray" }}
                // onChange={(e) => {
                //   setUserPassword(e.target.value);
                // }}
              />
              {/* <IconButton size="small">
                <ModeEditIcon fontSize="inherit" />
              </IconButton> */}
            </span>
          </Typography>
        </CardContent>
        <CardActions sx={{ position: "absolute", right: "10px", top: "10px" }}>
          <Tooltip placement="left" title={elm.status}>
            <Button size="small">
              <div className="d-flex w-100 justify-content-between mb-1">
                {true && (
                  <div className="d-flex justify-content-between w-75 h-50 mb-0 align-items-center ">
                    <Switch
                      checked={elm.status === "REGISTERED"}
                      onChange={() => handleSwitchButton()}
                    />
                  </div>
                )}
              </div>
            </Button>
          </Tooltip>

          <Button
            variant="custom-button"
            size="small"
            onClick={handleSyncButton}
            disabled={syncLoader}
          >
            {syncLoader ? (
              <CircularProgress size={15} color="inherit" />
            ) : (
              "Sync"
            )}
          </Button>
          <Button
            variant="custom-button"
            size="small"
            onClick={handlePullBookingButton}
            disabled={pullLoader}
          >
            Pull Bookings
            {/* {pullLoader ? (
              <CircularProgress size={15} color="inherit" />
            ) : (
              "Pull Bookings"
            )} */}
          </Button>
        </CardActions>
      </Card>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={style}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Enter the details</div>
              <div
                className="close"
                onClick={() => {
                  setOpen(false);
                }}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter username"
                  name="username"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter password"
                  name="password"
                  value={userPassword}
                  onChange={(e) => {
                    setUserPassword(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>

            <div className="amountWrapper">
              <div className="settleDuesSubmitBtns">
                <Button className="cancelBtn" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button variant="custom-button" onClick={postData}>
                  {loading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
