import React, { useEffect, useState } from "react";

import Table from "../../components/Table";
import {
  handleDjangoDataRequest,
  handleSpringDataRequest,
} from "../../utils/apiUtils";
import { toRegulerCase } from "../../utils/Utils";
import TableSkeleton from "../../components/TableSkeleton";
import Nodata from "../../components/Nodata";
import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";

export default function Payments({ hotelId }) {
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState();

  const [BookingId, setBookingId] = useState();
  const [pgPaymentId, setPgPaymentId] = useState();

  const [submitLoader, setSubmitLoader] = useState(false);

  const style = {
    position: "absolute",
    top: "40%",
    left: "45%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "4px",
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleColumn = (data) => {
    const culumnData = Object.keys(data[0]).map((elm) => ({
      field: elm,
      headerName: toRegulerCase(elm),
    }));
    setColumnDefs(culumnData);
  };
  //---------------Skeleton--------
  const [table, setTable] = useState(false);
  useEffect(() => {
    setTable(false);
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaGetPaymentsForHotel/?hotel_id=${hotelId}`
    )
      .then((data) => {
        setTable(true);
        handleColumn(data);
        setRowData(data);
      })
      .catch((err) => setTable(true));
  }, []);

  const handlePullButton = () => {
    if (!BookingId || !pgPaymentId) {
      toast.error("All fields are mandatory");
      return;
    }
    setSubmitLoader(true);
    handleSpringDataRequest(
      `core/api/v1/payment-service/reconcile-payment-meta?hotelId=${hotelId}&bookingId=${BookingId}&paymentId=${pgPaymentId}`
    )
      .then((data) => {
        setSubmitLoader(false);
        handleClose();
        toast.success(data);
      })
      .catch((err) => {
        setSubmitLoader(false);
        toast.error(err);
      });
  };

  return (
    <>
      <Button variant="custom-button" sx={{ mb: 2 }} onClick={handleOpen}>
        Manual Reconciliation
      </Button>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            Manual Reconciliation
          </Typography>
          <Divider light sx={{ mb: 2 }} />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              id="outlined-basic"
              label="Booking ID"
              variant="outlined"
              value={BookingId}
              sx={{ width: "300px" }}
              size="small"
              onChange={(e) => setBookingId(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="PG payment ID "
              variant="outlined"
              value={pgPaymentId}
              sx={{ width: "300px" }}
              size="small"
              onChange={(e) => setPgPaymentId(e.target.value)}
            />
          </Box>

          <Divider light sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleClose}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              variant="custom-button"
              onClick={handlePullButton}
              disabled={submitLoader}
            >
              Pull {submitLoader && <CircularProgress size="0.9rem" />}
            </Button>
          </Box>
        </Box>
      </Modal>
      {table ? (
        rowData ? (
          <Table rowData={rowData} columnDefs={columnDefs} />
        ) : (
          <Nodata />
        )
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}
