let firstPath = "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/";
export const channelLogoMapDash = {
  jasperrooms: firstPath + "jasperlogo.png",
  HOSTEL_WORLD: firstPath + "hostelworld.png",
  HOSTELWORLD: firstPath + "hostelworld.png",
  TRIPADVISOR: firstPath + "tripadvisor.png",
  TRIVAGO: firstPath + "trivago.png",
  HOTELBEDS: firstPath + "hotelbeds.png",
  HAPPYEASYGO: firstPath + "happyeasygo.png",
  TRAVELGURU: firstPath + "travelguru.png",
  GETAROOM: firstPath + "getaroom.jpeg",
  getaroom: firstPath + "getaroom.jpeg",
  BOOKING: firstPath + "bookingcom.png",
  "BOOKING.COM": firstPath + "bookingcom.png",
  "BOOKING.com": firstPath + "bookingcom.png",
  "GO-MMT": firstPath + "go-mmt.jpg",
  MAKEMYTRIP: firstPath + "makemytrip.png",
  GOIBIBO: firstPath + "goibibo.png",
  GOOMO: firstPath + "goomo.png",
  YATRA: firstPath + "yatra.png",
  CLEARTRIP: firstPath + "cleartrip.jpeg",
  AGODA: firstPath + "agoda.png",
  TRAVELOKA: firstPath + "traveloka.png",
  EASEMYTRIP: firstPath + "easemytrip.jpg",
  CTRIP: firstPath + "ctrip.jpg",
  PAYTM: firstPath + "paytm.jpeg",
  VIA: firstPath + "via_logo.jpg",
  "VIA.COM": firstPath + "via_logo.jpg",
  TIKET: firstPath + "tiket.png",
  "TIKET.COM": firstPath + "tiket.png",
  AIRBNB: firstPath + "airbnb.png",
  PRICELINE: firstPath + "priceline.png",
  "PRICELINE.COM": firstPath + "priceline.png",
  TRAVELOCITY: firstPath + "travelocity-logo-vector.png",
  "A-TRAVELOCITY": firstPath + "travelocity-logo-vector.png",
  ORBITZ: firstPath + "orbitz.png",
  "A-ORBITZ": firstPath + "orbitz.png",
  EXPEDIA: firstPath + "expedia.png",
  "A-EXPEDIA": firstPath + "expedia.png",
  "EXPEDIA AFFILIATE NETWORK": firstPath + "expedia_affiliate.png",
  "A-EXPEDIA AFFILIATE NETWORK": firstPath + "expedia_affiliate.png",
  EBOOKERS: firstPath + "ebookers-com-vector-logo.png",
  "A-EBOOKERS": firstPath + "ebookers-com-vector-logo.png",
  WOTIF: firstPath + "logo-wotif.png",
  "WOTIF.COM": firstPath + "logo-wotif.png",
  "A-WOTIF": firstPath + "logo-wotif.png",
  HOTWIRE: firstPath + "hotwire.png",
  "HOTWIRE.COM": firstPath + "hotwire.png",
  "A-HOTWIRE": firstPath + "hotwire.png",
  EGENCIA: firstPath + "logo-egencia-brand.png",
  "A-EGENCIA": firstPath + "logo-egencia-brand.png",
  "THOMAS COOK": firstPath + "logo-thomas-cook.png",
  "A-THOMAS COOK": firstPath + "logo-thomas-cook.png",
  "HOTELS.COM": firstPath + "Hotels.com_logo.png",
  "A-HOTELS.COM": firstPath + "Hotels.com_logo.png",
  HOTELS: firstPath + "Hotels.com_logo.png",
  CHEAPTICKETS: firstPath + "cheaptickets-01.jpg",
  "CHEAPTICKETS.COM": firstPath + "cheaptickets-01.jpg",
  "A-CHEAPTICKETS": firstPath + "cheaptickets-01.jpg",
  STAYFLEXI_HD: firstPath + "favicon/walkin.png",
  "WALK-IN": firstPath + "favicon/walkin.png",
  "Walk-In": firstPath + "favicon/walkin.png",
  STAYFLEXI_OD: firstPath + "sflogos.png",
  STAYFLEXI_OM: firstPath + "sflogos.png",
  "STAYFLEXI MOBILE": firstPath + "sflogos.png",
  STAYFLEXI_GHA: firstPath + "sflogos.png",
  STAYFLEXI_BE: firstPath + "sflogos.png",
  STAYFLEXI_MOBILE: firstPath + "sflogos.png",
};
