import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

export default function GroupFeaturesLoader() {
  return (
    <Box sx={{ mt: 6 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={60} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rectangular" height={60} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={60} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rectangular" height={60} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={60} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rectangular" height={60} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={60} />
        </Grid>
      </Grid>
    </Box>
  );
}
