import { Box, Typography } from "@mui/material";
import React from "react";

export default function Nodata() {
  return (
    <Box
      sx={{
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
      }}
    >
      <Typography variant="h6" sx={{ color: "gray" }}>
        No data available.......
      </Typography>
    </Box>
  );
}
