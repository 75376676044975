import React, { useEffect, useState } from "react";

import Table from "../../components/Table";
import { Box, Button, ClickAwayListener, Stack } from "@mui/material";
import { formatDateRange, toRegulerCase } from "../../utils/Utils";
import { handleDjangoDataRequest } from "../../utils/apiUtils";
import TableSkeleton from "../../components/TableSkeleton";

import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Nodata from "../../components/Nodata";

export default function Commissions({ hotelId }) {
  const [rowData, setRowData] = useState();

  const [columnDefs, setColumnDefs] = useState();
  // [
  //   { field: "make" },
  //   { field: "model" },
  //   { field: "price" },
  //   { field: "type" },
  // ];
  const currencyFormatter = (params) => {
    return params.value.toFixed(2);
  };
  const handleColumn = (data) => {
    // if (data) {
    //   const culumnData = Object.keys(data[0]).map((elm) => ({
    //     field: elm,
    //     headerName: toRegulerCase(elm),
    //   }));
    //   setColumnDefs(culumnData);
    // }
    const culumnData = Object.keys(data[0])
      .filter((elm) => elm !== "id")
      .map((elm) => {
        if (
          elm === "commission" ||
          elm === "total" ||
          elm === "refund" ||
          elm === "booking_amount" ||
          elm === "commission_amount" ||
          elm === "commission_percentage"
        ) {
          return {
            field: elm,
            headerName: toRegulerCase(elm),
            valueFormatter: currencyFormatter,
          };
        } else {
          return {
            field: elm,
            headerName: toRegulerCase(elm),
          };
        }
      });
    setColumnDefs(culumnData);
  };

  //-------------------
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleShowButton = () => {
    setTable(false);
    const formatedDate = formatDateRange(dateRange);
    console.log(formatedDate);
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaCommissionLogsForHotel/?hotel_id=${hotelId}&daterange=${formatedDate}`
    )
      .then((data) => {
        setTable(true);
        handleColumn(data);
        setRowData(data);
        console.log(data);
      })
      .catch((err) => setTable(true));
  };
  useEffect(() => {
    handleShowButton();
  }, []);
  //---------------Skeleton--------
  const [table, setTable] = useState(false);
  //open and close date picker
  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ display: "flex", marginBottom: 1 }}>
        <Stack spacing={4} className="position-relative" sx={{ mt: 1 }}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: "relative" }}>
              <Button variant="outlined" onClick={handleClick} fullWidth>
                {moment(dateRange[0].startDate).format("DD-MM-YY")} {" to "}
                {moment(dateRange[0].endDate).format("DD-MM-YY")}
              </Button>
              {open ? (
                <Box sx={styles}>
                  <Stack spacing={3}>
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRange}
                    />
                    <Stack
                      className="position-relative w-100 d-flex justify-content-between"
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="custom-button"
                        onClick={() => {
                          handleShowButton();
                          handleClickAway();
                          console.log(dateRange);
                        }}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              ) : null}
            </Box>
          </ClickAwayListener>
        </Stack>
        <Button
          variant="custom-button"
          sx={{ mb: 1, mt: 1, ml: 2 }}
          onClick={handleShowButton}
        >
          Show Data
        </Button>
      </Box>
      {table ? (
        rowData ? (
          <Table rowData={rowData} columnDefs={columnDefs} />
        ) : (
          <Nodata />
        )
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}
