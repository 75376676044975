import React, { useMemo } from "react";

import "ag-grid-enterprise"; //AG Grid Enterprise
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
// import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";

import "../css/Table.css";

export default function TableAirbnb({
  rowData,
  columnDefs,
  onSelectionChanged,
  onGridReady,
}) {
  const gridStyle = useMemo(() => ({ height: "85vh", width: "100%" }), []);

  const defaultColDef = useMemo(() => {
    return {
      // sortable: true,
      filter: "agTextColumnFilter",
      // floatingFilter: true,
      resizable: true,
      suppressMenu: true,
      headerClass: "custom-header",
    };
  }, []);

  const sideBar = () => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
      defaultToolPanel: "",
    };
  };

  return (
    <div>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          className="agGridTable"
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          sideBar={sideBar()} //To set colum show/hide sidebar
          pagination={true} //To set Pagination on Bottom
          rowHeight={42.5}
          // onSelectionChanged={onSelectionChanged}
          // onGridReady={onGridReady}
          // suppressRowClickSelection={true}
          // rowSelection={"multiple"}
        />
      </div>
    </div>
  );
}
