import React, { useEffect, useState } from "react";
import ChannelCard from "./ChannelCard";
import { handleSpringDataRequest } from "../../../../utils/apiUtils";

export default function DisplayMapedChannels({ hotelId }) {
  const [registeredChannes, setRegisteredChannels] = useState();
  const [toRerender, setToRerender] = useState();

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/cm/get-all-channel-list/?hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((data) => {
        setRegisteredChannels(data);
        console.log(data);
      })
      .catch((err) => console.log(err));
  }, [toRerender]);
  return (
    <div
      style={{
        boxShadow: "4px 4px 5px #D3D3D3",
      }}
    >
      {registeredChannes?.map((elm) => (
        <ChannelCard
          elm={elm}
          setToRerender={setToRerender}
          hotelId={hotelId}
        />
      ))}
    </div>
  );
}
