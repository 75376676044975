import { Box, Modal } from "@mui/material";
import React from "react";
import RawmappingPage from "./RawmappingPage";

export default function RawMappingModal({ show, hide, hotelId }) {
  return (
    <Modal
      open={show}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          left: "15vw",
          top: 0,
          height: "100vh",
          width: "85vw",
          backgroundColor: "#ffffff",
          overflow: "auto",
        }}
      >
        <span
          style={{
            position: "absolute",
            left: "20px",
            top: "20px",
            cursor: "pointer",
          }}
          onClick={hide}
        >
          X
        </span>
        <RawmappingPage hide={hide} hotelId={hotelId} />
      </Box>
    </Modal>
  );
}
