import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import CreateUser from "./access/CreateUser";
import {
  handleDjangoDataRequest,
  handleSpringDataMutationRequest,
} from "../utils/apiUtils";
import { toast } from "react-toastify";

export default function AccesControl() {
  const [refresh, setRefresh] = useState();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    handleDjangoDataRequest("api/v2/metaAdmin/metaAdminAccessControl/")
      .then((data) =>
        setUsers(
          data.allUsers.sort(function (a, b) {
            return a.email.localeCompare(b.email);
          })
        )
      )
      .catch((err) => console.log(err));
  }, [refresh]);
  //-------------handle Edit User-------------
  const [selectedUser, setSelectedUser] = useState({});
  const handleEdituser = (elm) => {
    setSelectedUser(elm);
    handleOpen();
  };
  //-----------handleAddNewUser-----------
  const handleAddNewUser = () => {
    setSelectedUser({});
    handleOpen();
  };
  //----------------------Modal------------
  const style = {
    position: "absolute",
    top: "0%",
    right: "0%",
    // transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  //------------------Modal Delete------------------------------
  const [selectedEmail, setSelectedEmail] = useState();
  const delstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "8px",
  };
  const [delopen, setDelOpen] = useState(false);
  const handleDelClose = () => {
    setDelOpen(false);
  };
  const handleDelOpen = () => {
    setDelOpen(true);
  };
  const handleDelIcon = (email) => {
    setSelectedEmail(email);
    handleDelOpen();
  };

  const handleDeleteUser = () => {
    handleSpringDataMutationRequest("POST", "user/deleteMetaAdmin", {
      username: selectedEmail,
    })
      .then((data) => {
        toast.success("User Successfully Removed");
        setRefresh(new Date());
        handleDelClose();
      })
      .catch((err) => toast.error("Something went wrong"));
  };
  //---------------------------------

  return (
    <>
      {/* <Card sx={{ mb: 1 }}>
        <CardHeader
          title={"Access Control"}
          
        ></CardHeader>
      </Card> */}
      {/* <Divider /> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          pl: 2,
          mt: 2,
          mb: 2,
        }}
      >
        <Typography sx={{ fontSize: "1.5rem" }}>Access Control</Typography>
        <Button variant="custom-button" onClick={handleAddNewUser}>
          Add New User
        </Button>
      </Box>
      <Divider />

      <Box sx={{ height: "100vh", overflow: "scroll", p: 2 }}>
        {users.map((elm) => (
          <Card sx={{ "&:hover": { boxShadow: 8 }, mb: 2 }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pl: 1,
                  pr: 1,
                }}
              >
                <Typography>{elm.email}</Typography>
                <Box>
                  <IconButton color="primary">
                    <Tooltip title="Edit" placement="top">
                      <BorderColorIcon
                        onClick={() => {
                          handleEdituser(elm);
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                  <IconButton color="primary">
                    <Tooltip title="Delete" placement="top">
                      <DeleteForeverIcon
                        onClick={() => handleDelIcon(elm.email)}
                      />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Box>
              <Divider sx={{ m: 1 }} />
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(elm.accessmap)
                  .filter((data) => elm.accessmap[data] === true)
                  .sort((a, b) => a - b)
                  .map((ell) => (
                    <Chip label={ell} sx={{ ml: 1, mb: 1 }} />
                  ))}
              </Box>
            </CardContent>
          </Card>
        ))}
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CloseIcon
              fontSize="small"
              sx={{ position: "absolute", top: 6, right: 6, cursor: "pointer" }}
              onClick={handleClose}
            />
            <Divider sx={{ mt: 4 }} />
            <CreateUser
              handleClose={handleClose}
              selectedUser={selectedUser}
              setRefresh={setRefresh}
            />
          </Box>
        </Modal>
        {/* -----------------------------------------Delete Modal----------------- */}
        <Modal
          open={delopen}
          onClose={handleDelClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={delstyle}>
            <CloseIcon
              fontSize="small"
              sx={{ position: "absolute", top: 6, right: 6, cursor: "pointer" }}
              onClick={handleDelClose}
            />

            <Divider sx={{ mt: 4 }} />
            <Typography>
              You are about to delete User . Do you want to proceed?
            </Typography>
            <Button
              color="error"
              variant="outlined"
              sx={{ m: 1 }}
              onClick={handleDelClose}
            >
              No
            </Button>
            <Button
              variant="custom-button"
              sx={{ m: 1 }}
              onClick={handleDeleteUser}
            >
              Yes
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
