import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  ListItemText,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

export default function ChannelManager() {
  return (
    <>
      <Grid item xs={12} sx={{ "&:hover": { boxShadow: 8 } }}>
        <Card sx={{ marginBottom: 2 }}>
          <CardHeader
            title={"Channel Name"}
            action={
              <Grid item spacing={2} container>
                <Grid item>
                  <IconButton color="primary">
                    <Edit />
                  </IconButton>
                  <IconButton color="primary">
                    {" "}
                    <PowerSettingsNewIcon />
                  </IconButton>

                  <IconButton color="primary">
                    <PlayCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            }
          >
            <Divider />
          </CardHeader>
          <Divider />
          <CardContent>
            <Grid item container spacing={3}>
              <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                <ListItemText
                  primary="Name"
                  secondary={"num.name"}
                ></ListItemText>
              </Grid>
              <Grid item xs={4} sm={3} md={6} lg={6} xl={6} mr={6}>
                <ListItemText
                  primary="Tax Percentage"
                  secondary={"num.percentage"}
                ></ListItemText>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sx={{ "&:hover": { boxShadow: 8 } }}>
        <Card sx={{ marginBottom: 2 }}>
          <CardHeader
            title={"Channel Name"}
            action={
              <Grid item spacing={2} container>
                <Grid item>
                  <IconButton color="primary">
                    <Edit />
                  </IconButton>
                  <IconButton color="primary">
                    <PowerSettingsNewIcon />
                  </IconButton>

                  <IconButton color="primary">
                    <PlayCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            }
          >
            <Divider />
          </CardHeader>
          <Divider />
          <CardContent>
            <Grid item container spacing={3}>
              <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                <ListItemText
                  primary="Name"
                  secondary={"num.name"}
                ></ListItemText>
              </Grid>
              <Grid item xs={4} sm={3} md={6} lg={6} xl={6} mr={6}>
                <ListItemText
                  primary="Tax Percentage"
                  secondary={"num.percentage"}
                ></ListItemText>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
