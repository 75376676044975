import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Name: "MetaAdmin",
  Token: "",
  Email: "",
  Access: {},
  PropertyList: [],
};

const store = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    saveName: (state, action) => {
      state.Name = action.payload;
    },
    saveToken: (state, action) => {
      state.Token = action.payload;
    },
    saveEmail: (state, action) => {
      state.Email = action.payload;
    },
    saveAccess: (state, action) => {
      state.Access = action.payload;
    },
    savePropertyList: (state, action) => {
      state.PropertyList = action.payload;
    },
  },
});

export const { saveName, saveToken, saveEmail, saveAccess, savePropertyList } =
  store.actions;

export default store.reducer;
