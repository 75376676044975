import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

function ReportsLoader() {
  const arr = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Skeleton variant="rounded" height={40} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rounded" height={40} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rounded" height={40} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rounded" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ height: 20 }} />
      </Grid>
      {arr?.map(() => (
        <Grid item xs={2}>
          <Skeleton variant="rounded" height={20} />
        </Grid>
      ))}
    </Grid>
  );
}

export default ReportsLoader;
