import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";

export default function TableSkeleton() {
  const arr = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0,
  ];
  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>
        </Grid>

        {arr.map(() => (
          <Grid item xs={2}>
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
