import React, { useEffect, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../utils/apiUtils";
import { toast } from "react-toastify";

export default function STRConfiguration({ hotelId, hotelName }) {
  const [strStatus, setStrStatus] = useState("");
  const [strId, setStrId] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const gettingStrStatus = () => {
    handleSpringDataRequest(
      `core/api/v1/reports/get-str-configuration?hotelId=${hotelId}`
    )
      .then((data) => {
        setStrStatus(data?.enabled);
      })
      .catch(() => {});
  };

  const updateStr = () => {
    setSaveLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reports/add-str-configuration?hotelId=${hotelId}`,
      { hotelId: hotelId, strId: strId, enabled: strStatus }
    )
      .then(() => {
        toast.success("STR updated successfully");
        setSaveLoader(false);
      })
      .catch(() => {
        toast.error("something went wrong");
        setSaveLoader(false);
      });
  };
  useEffect(() => {
    gettingStrStatus();
  }, []);
  return (
    <Box>
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader
          title={"STR configuration"}
          // subheader={"Competitor description"}
        >
          <Divider />
        </CardHeader>
        <Divider />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              margin: "auto",
              gap: 4,
            }}
          >
            <TextField
              size="small"
              id="outlined-basic"
              label="STR Id"
              variant="outlined"
              value={strId}
              onChange={(e) => setStrId(e.target.value)}
            />
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">STR Status</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={strStatus}
                label="STR Status"
                onChange={(e) => setStrStatus(e?.target?.value)}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            <Button
              size="small"
              variant="outlined"
              onClick={updateStr}
              disabled={saveLoader}
              sx={{ textTransform: "none" }}
            >
              Configure STR {saveLoader && <CircularProgress size={"1rem"} />}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
