import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";

export function BasicInfoSkeleton() {
  const arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h1">
          <Skeleton animation="wave" />
        </Typography>
      </Grid>
      <Divider />

      {arr.map(() => (
        <Grid item xs={4}>
          <Typography sx={{ fontSize: "13px" }}>
            <Skeleton animation="wave" />
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export function BasicInfoSkeletonpg() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Typography variant="h1">
          <Skeleton animation="wave" />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6">
          <Skeleton animation="wave" />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6">
          <Skeleton animation="wave" />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6">
          <Skeleton animation="wave" />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6">
          <Skeleton animation="wave" />
        </Typography>
      </Grid>
    </Grid>
  );
}
