import { Grid, Button, Box, Typography, Divider } from "@mui/material";
import React, { useState } from "react";
import DisplayMapedChannels from "./MappedChannels/DisplayMapedChannels";
import RawMappingModal from "./RawMapping/RawMappingModal";
import ChannelModal from "./GUIMapping/ChannelModal";

function Channel({ hotelId }) {
  const [addNewTaxCategory, setAddNewTaxCategory] = useState(false);

  const [displayRawMappingModal, setDisplayRawMappingModal] = useState(false);
  const openRawmappingModal = () => {
    setDisplayRawMappingModal(true);
  };
  const closeRawMappingModal = () => {
    setDisplayRawMappingModal(false);
  };

  const openAddNewTaxCategoryModal = () => {
    setAddNewTaxCategory(true);
  };
  const hideAddNewTaxCategoryModal = () => {
    setAddNewTaxCategory(false);
  };

  return (
    <Box>
      <Typography variant="h6">Channel Mapping</Typography>
      <Typography gutterBottom sx={{ paddingRight: "4px" }}>
        To sell hotel rooms on multiple online platforms use this channel
        mapping process. Channel mapping is the process of linking a hotel’s
        room types in Stayflexi Property Management System to specific online
        distribution channels or travel agencies. This ensures that the same
        room type is listed with the same name, description, and rate across all
        channels. By mapping a channel, a direct link is created with the
        stayflexi system, ensuring that inventory, price, and restrictions are
        always up-to-date and accurately reflected across all distribution
        channels.
      </Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid item container sx={{ marginBottom: 3 }}>
        <Grid item xs={6}>
          <Button
            onClick={() => openAddNewTaxCategoryModal()}
            variant="custom-button"
          >
            Add / Edit Channel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => openRawmappingModal()} variant="custom-button">
            Add / Edit Channel (Legacy Mapping)
          </Button>
        </Grid>
      </Grid>
      <DisplayMapedChannels hotelId={hotelId} />
      <ChannelModal
        show={addNewTaxCategory}
        hide={hideAddNewTaxCategoryModal}
        hotelId={hotelId}
      />
      <RawMappingModal
        show={displayRawMappingModal}
        hide={closeRawMappingModal}
        hotelId={hotelId}
      />
    </Box>
  );
}

export default Channel;
