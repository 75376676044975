import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Channel from "../propertyDashboard/ChannelMapper/Channel";
import { useState } from "react";

export default function ChannelMappingForHotel() {
  const [hotelId, setHotelId] = useState("");
  const [inputHotelId, setInputHotelId] = useState("");
  const handleChangeHotelId = (e) => {
    setHotelId("");
    setInputHotelId(e);
  };
  const handleProceed = () => {
    setHotelId(inputHotelId);
  };
  return (
    <Box>
      <Typography sx={{ fontSize: "13px" }} gutterBottom>
        Please enter the hotel ID
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            autoComplete="off"
            type="text"
            id="outlined-basic"
            label="Hotel Id"
            variant="outlined"
            size="small"
            value={inputHotelId}
            onChange={(e) => handleChangeHotelId(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Button type="submit" variant="custom-button" onClick={handleProceed}>
            Proceed
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ m: 1 }} />
      {hotelId && <Channel hotelId={hotelId} />}
    </Box>
  );
}
