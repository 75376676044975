import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import { handleSpringDataRequest } from "../../../utils/apiUtils";
import { useEffect } from "react";
import CompanyInfoManagement from "./CompanyInfoManagement";
import TransactionsDashBoardManagement from "./TransactionsDashBoardManagement";
import PaymentMethodManagement from "./PaymentMethodManagement";
import "./style.css";
import AccountPayments from "./AccountPayments";

export default function AccountsDashBoardManagement() {
  //------------Tab Pannel-------------
  const [value, setValue] = useState("Account Info");

  const handleChange = (e) => {
    setValue(e.target.textContent);
  };
  //-----------------------
  const groupId = new URLSearchParams(window.location.search).get("group_id");
  const [hotelCurr, setHotelCurr] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);

  // const getCurrencyData = () => {
  //   handleSpringDataRequest(
  //     `api/v2/metaAdmin/metaAdminManageHotel/?hotel_id=${groupId}`
  //   )
  //     .then((data) => {
  //       setHotelCurr(data.hotel?.hotel_currency);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const getCompanyData = () => {
    handleSpringDataRequest(
      `core/api/v1/account/get-single?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((data) => {
        setAccountInfo(data);
        if (data?.account_details?.billing_address_country === "India") {
          setHotelCurr("INR");
        } else {
          setHotelCurr("USD");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCompanyData();
    // getCurrencyData();
  }, []);

  //   useEffect(() => {
  //     location &&
  //       location?.state &&
  //       location.state !== undefined &&
  //       setHotelsData(location.state);
  //     location &&
  //       location?.state &&
  //       location.state !== undefined &&
  //       setBookingData(location.state.bookingData);
  //     location &&
  //       location?.state &&
  //       location.state !== undefined &&
  //       setHotelIds(location.state.hotelIds);
  //     location &&
  //       location?.state &&
  //       location.state !== undefined &&
  //       setGroupId(location.state.groupId);
  //   }, []);

  //   useEffect(async () => {
  //     if (hash && hash !== "") {
  //       await axios
  //         .post(
  //           `${process.env.REACT_APP_SPRING_API_URL}` +
  //             "core/api/v1/account/validate-hash",
  //           {
  //             hashval: hash.split(" ").join("+"),
  //           }
  //         )
  //         .then((resp) => {
  //           setGroupId(resp?.data?.groupId);
  //           setHotelCurr(resp?.data?.hotelCurrency);
  //           setIsValidHash(true);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  //   }, [hash]);

  return (
    <Box sx={{ width: "100%", typography: "h6" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Account Info" value="Account Info" />
            <Tab label="Account Ledger" value="Account Ledger" />
            {hotelCurr !== "INR" && (
              <Tab label="Payment Methods" value="Payment Methods" />
            )}
            <Tab label="Payments" value="Payments" />
          </TabList>
        </Box>

        <TabPanel value="Account Info" sx={{ backgroundColor: "#F8F8F8" }}>
          <CompanyInfoManagement
            groupId={groupId}
            res={accountInfo}
            isLanding={"NO"}
            hash={groupId}
            curr={hotelCurr}
          />
        </TabPanel>
        <TabPanel value="Account Ledger">
          <TransactionsDashBoardManagement
            groupId={groupId}
            res={accountInfo}
            hash={groupId}
            curr={hotelCurr}
            getCompanyData={getCompanyData}
          />
        </TabPanel>
        <TabPanel value="Payment Methods">
          <PaymentMethodManagement
            groupId={groupId}
            res={accountInfo}
            hash={groupId}
          />
        </TabPanel>
        <TabPanel value="Payments">
          <AccountPayments groupId={groupId} res={accountInfo} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
