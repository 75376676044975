import React from "react";
import { Button, TableCell, TableRow, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { handleSpringDataMutationRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";

export default function SingleRateRow({ row, getRateShoperData }) {
  const [bcomUrl, setBcomUrl] = useState("");
  const [cityLocation, setCityLocation] = useState("");
  useEffect(() => {
    setBcomUrl(row?.bcom_url);
    setCityLocation(row?.city_location);
  }, [row]);

  const handleSaveBcom = () => {
    const payload = [
      {
        id: row?.id,
        zipCode: row?.zip_code,
        cityLocation: cityLocation,
        bcomUrl: bcomUrl,
      },
    ];
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/rate-shopper/add-update-url`,
      payload
    )
      .then((msg) => {
        toast.success("Rates will be fetched in 5 mins");
        getRateShoperData();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row?.emailId}>
      <TableCell>{row?.id}</TableCell>
      <TableCell>
        <TextField
          label="Bcom URL"
          id="outlined-size-small"
          size="small"
          value={bcomUrl}
          onChange={(e) => setBcomUrl(e?.target?.value)}
        />
      </TableCell>
      <TableCell>
        <TextField
          label="City location"
          id="outlined-size-small"
          size="small"
          value={cityLocation}
          onChange={(e) => setCityLocation(e?.target?.value)}
        />
      </TableCell>
      <TableCell>{row?.zip_code}</TableCell>
      <TableCell align="right">
        <Button variant="custom-button" onClick={handleSaveBcom}>
          Save
        </Button>
      </TableCell>
    </TableRow>
  );
}
