import React, { useEffect, useState } from "react";

import { Button, TextareaAutosize } from "@mui/material";
import { handleSpringDataRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";
import TableSkeleton from "../../components/TableSkeleton";
import Nodata from "../../components/Nodata";
import TableGoogle from "../../components/TableGoogle";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

export default function Google({ hotelId }) {
  const [rowData, setRowData] = useState([]);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "message",
      headerName: "Data",
      width: 1000,
      cellRenderer: dataCell,
    },
    {
      field: "updateTime",
      headerName: "Update Time",
    },
  ]);

  //---------------Skeleton--------
  const [table, setTable] = useState(false);
  useEffect(() => {
    setTable(false);
    handleSpringDataRequest(
      `metaAdmin/api/v1/get-google-push-data?hotelId=${hotelId}`
    )
      .then((data) => {
        setTable(true);
        setRowData([data]);
      })
      .catch((err) => setTable(true));
  }, []);
  const handlePushButton = () => {
    setLoader(true);
    handleSpringDataRequest(
      `metaAdmin/api/v1/google-push-data?hotelId=${hotelId}`
    )
      .then((data) => {
        toast.success(data);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setLoader(false);
      });
  };
  //------------------Loader---------------------
  const [loader, setLoader] = useState(false);
  return (
    <>
      <Button variant="custom-button" sx={{ mb: 2 }} onClick={handlePushButton}>
        {loader ? <CircularProgress size={24} /> : "Push Data"}
      </Button>
      {table ? (
        rowData ? (
          <TableGoogle rowData={rowData} columnDefs={columnDefs} />
        ) : (
          <Nodata />
        )
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}

export function dataCell({ value }) {
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 24px ${
      theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  return (
    <StyledTextarea
      aria-label="empty textarea"
      placeholder="Empty"
      value={value}
      minRows={22}
    />
  );
}
