import {
  CircularProgress,
  Grid,
  OutlinedInput,
  Button,
  IconButton,
  Tooltip,
  Switch,
  Box,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import "../propertyDashboard/styles/AccessControlModal.css";
import { Table } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import { handleSpringDataRequest } from "../../utils/apiUtils";
import { handleparamValue } from "../../utils/Utils";
import GroupFeaturesLoader from "./Loader/GroupFeaturesLoader";

const GroupReplenish = ({ hotelId, emailId }) => {
  const [access, setAccess] = useState([]);
  // const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(false);
  // const [search, setSearch] = useState("");

  const handleCheckboxChange = (row) => {
    // debugger;
    // console.log("roww", row);
    const arr = [...access];
    arr.map((elm) => {
      if (elm?.hotelId === row?.hotelId) {
        elm.enabled = row?.enabled === "yes" ? "no" : "yes";
        return elm;
      }
      return elm;
    });
    // console.log("arr,arr", arr);
    setAccess(arr);
  };

  const handleAll = (e) => {
    console.log(e.target.checked);
    const arr = [...access];
    arr.map((item) => {
      item.enabled = e.target.checked ? "yes" : "no";
      return item;
    });
    setAccess(arr);
  };

  const saveGroupAccess = () => {
    access?.map((row, idx) => {
      handlingReplenishData(
        row?.hotelId,
        row?.enabled,
        access?.length - idx <= 1
      );
    });
  };

  // const handleSearch = (value) => {
  //   setSearch(value);
  //   if (value.trim()) {
  //     const arr = allData.filter((elm) =>
  //       elm?.displayName?.toLowerCase().includes(value.trim()?.toLowerCase())
  //     );
  //     setAccess(arr);
  //   }
  // };

  //------------------replenish-inv-group-----------
  const handlingReplenishData = (hotelId, status, isLastRow) => {
    setLoader(true);
    handleSpringDataRequest(
      `core/api/v1/pc/update-auto-replenish-inv?hotelId=${hotelId}&type=${status}`
    )
      .then(() => {
        // getReplenishData();
        setLoader(false);
        if (isLastRow) {
          getReplenishData();
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getReplenishData = () => {
    handleSpringDataRequest(
      `core/api/v1/pc/get-group-auto-replenish-inv?emailId=${emailId}`
    )
      .then((data) => {
        setAccess(data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getReplenishData();
  }, []);

  return (
    <Box>
      <Card style={{ width: "100%", height: "100%" }}>
        <Card.Body style={{ width: "100%" }}>
          {access?.length === 0 || loader ? (
            <GroupFeaturesLoader />
          ) : (
            <>
              <Grid item xs={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="d-flex flex-column w-100">
                    {/* <div className="d-flex">
                      <OutlinedInput
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder="Search By Access Control Name"
                        label="Search By Access Control Name"
                        notched={false}
                        type="search"
                        size="small"
                        style={{
                          borderRadius: "5px",
                          width: "35%",
                          marginBottom: "2rem",
                          marginTop: "1rem",
                          marginRight: "2rem",
                        }}
                      />
                    </div> */}

                    <div
                      style={{
                        overflowY: "scroll",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Hotel id</th>
                            <th>
                              <Switch
                                className="mr-2 mb-0"
                                checked={
                                  access.filter((elm) => elm?.enabled === "yes")
                                    .length === access?.length
                                }
                                onChange={(e) => handleAll(e)}
                              />
                              All Access
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {access?.map((row, idx) => (
                            <tr key={idx}>
                              <td>
                                <span className="d-flex align-item-center">
                                  <p
                                    className="mr-1 mb-0"
                                    style={{ marginTop: "7px" }}
                                  >
                                    {row?.hotelId}
                                  </p>
                                  <Tooltip
                                    title={
                                      <Typography sx={{ fontSize: "18px" }}>
                                        {row?.hotelId}
                                      </Typography>
                                    }
                                    placement="right"
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              </td>
                              <td>
                                <Switch
                                  checked={row?.enabled === "yes"}
                                  onChange={() => handleCheckboxChange(row)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Grid>
              </Grid>

              {/* <FormControl
            size="small"
            fullWidth
            style={{ width: "18rem", marginTop: "1rem" }}
          >
            <InputLabel
              style={{ width: "15=8rem" }}
              id="demo-simple-select-label"
            >
              Payment Necessary during checkin
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => setselectedModeofPayment(e.target.value)}
              size="small"
              style={{ marginLeft: "0rem", width: "18rem" }}
              className="mr-4"
              label="Payment Necessary during checkin"
              value={selectedModeofPayment}
            >
              <MenuItem value={"UNRESTRICTED"}>{"UNRESTRICTED"}</MenuItem>
              <MenuItem value={"PARTIAL PAYMENT"}>{"PARTIAL PAYMENT"}</MenuItem>
              <MenuItem value={"FULL PAYMENT"}>{"FULL PAYMENT"}</MenuItem>
            </Select>
          </FormControl> */}
              <Button
                className="float-right mr-4"
                variant="custom-button"
                onClick={() => saveGroupAccess()}
                disabled={loader}
              >
                Save
                {loader && (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                )}
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </Box>
  );
};

export default GroupReplenish;
