import React from "react";
import Table from "../../../components/Table";

export default function HiddenTable({
  rowData,
  columnDefs,
  gridOptions,
  onGridReady,
  onSelectionChanged,
}) {
  return (
    <div>
      <Table
        rowData={rowData}
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
      />
    </div>
  );
}
