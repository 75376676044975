import React, { useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Modal,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { handleSpringDataRequest } from "../../utils/apiUtils";
import CloseIcon from "@mui/icons-material/Close";
import EditChannel from "./EditChannel";
import DeleteChannel from "./DeleteChannel";

export default function Channels() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  //----------------------------
  const [singleChannel, setSingleChannel] = useState({});
  const [action, setAction] = useState("");
  const handleEdit = (elm) => {
    setAction("edit");
    setSingleChannel(elm);
    handleOpen();
  };
  const handleDelete = (elm) => {
    setAction("delete");
    setSingleChannel(elm);
    handleOpen();
  };
  //----------------------
  const [channels, setChannels] = useState([]);
  const [refresh, setRefresh] = useState();

  useEffect(() => {
    handleSpringDataRequest(`core/api/v1/cm/get-all-channels?hotelId=1234`)
      .then((data) => {
        setChannels(data);
        console.log(data);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  //-------------------------

  return (
    <Box sx={{ overflow: "scroll", height: "100vh" }}>
      {channels.map((elm) => (
        <Card sx={{ "&:hover": { boxShadow: 8 }, mb: 2 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pl: 1,
                pr: 1,
              }}
            >
              <Typography variant="button">{elm.channelName} </Typography>
              <Box>
                <IconButton color="primary">
                  <Tooltip title="Edit" placement="top">
                    <BorderColorIcon onClick={() => handleEdit(elm)} />
                  </Tooltip>
                </IconButton>
                <IconButton color="primary">
                  <Tooltip title="Delete" placement="top">
                    <DeleteForeverIcon onClick={() => handleDelete(elm)} />
                  </Tooltip>
                </IconButton>
              </Box>
              {/* <SpeedDial
                ariaLabel="SpeedDial basic example"
                icon={<SpeedDialIcon />}
                direction="left"
                sx={{ height: "10px" }}
              >
                <SpeedDialAction
                  icon={
                    <DeleteForeverIcon
                      sx={{ color: "white" }}
                      onClick={handleDelete}
                    />
                  }
                  tooltipTitle={"Delete"}
                />
                <SpeedDialAction
                  icon={
                    <BorderColorIcon
                      sx={{ color: "white" }}
                      onClick={() => handleEdit(elm)}
                    />
                  }
                  tooltipTitle={"Edit"}
                />
              </SpeedDial> */}
            </Box>

            <Divider sx={{ m: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography
                  sx={{ fontWeight: "medium", fontSize: "15px", color: "gray" }}
                >
                  Name
                </Typography>
                <Typography sx={{ fontSize: "13px" }}>
                  {elm.channelName}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{ fontWeight: "medium", fontSize: "15px", color: "gray" }}
                >
                  Username
                </Typography>
                <Typography sx={{ fontSize: "13px" }}>
                  {elm.username}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{ fontWeight: "medium", fontSize: "15px", color: "gray" }}
                >
                  Password
                </Typography>
                <Typography sx={{ fontSize: "13px" }}>
                  {elm.password}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{ fontWeight: "medium", fontSize: "15px", color: "gray" }}
                >
                  API Key
                </Typography>
                <Typography sx={{ fontSize: "13px" }}>{elm.apiKey}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{ fontWeight: "medium", fontSize: "15px", color: "gray" }}
                >
                  Channel API Key
                </Typography>
                <Typography sx={{ fontSize: "13px" }}>
                  {elm.chApiKey}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            fontSize="small"
            sx={{ position: "absolute", top: 6, right: 6, cursor: "pointer" }}
            onClick={handleClose}
          />

          <Divider sx={{ mt: 4 }} />
          {action === "edit" && (
            <EditChannel
              singleChannel={singleChannel}
              handleClose={handleClose}
              setRefresh={setRefresh}
            />
          )}
          {action === "delete" && (
            <DeleteChannel
              singleChannel={singleChannel}
              handleClose={handleClose}
              setRefresh={setRefresh}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
}
