import React, { useState, useEffect } from "react";
import TransactionsManagement from "./TransactionsManagement";
import { handleSpringDataRequest } from "../../../utils/apiUtils";

export default function RevenueDashboardManagement(props) {
  const [groupId, setGroupId] = useState(props?.groupId);
  const [accountInfo, setAccountInfo] = useState(null);

  const [ledger, setLedger] = useState(null);
  const [accBalance, setAccBalance] = useState(null);

  const getCompanyData2 = () => {
    handleSpringDataRequest(
      `core/api/v1/account/get-single?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        setAccountInfo(res);
      })
      .catch((err) => console.log(err));
  };

  const getLedgerData = () => {
    handleSpringDataRequest(
      `core/api/v1/account-ledger/get-all-invoices?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        setLedger(res);
      })
      .catch((err) => console.log(err));
  };

  const getAccountBalance = () => {
    handleSpringDataRequest(
      `core/api/v1/account-ledger/get-account-balance?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        setAccBalance(res?.message);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    groupId && getCompanyData2();
    groupId && getLedgerData();
    groupId && getAccountBalance();
  }, [groupId]);

  return (
    <div>
      {accountInfo && ledger && accBalance && (
        <TransactionsManagement
          ledger={ledger}
          getAccountBalance={getAccountBalance}
          accBalance={accBalance}
          groupId={groupId}
          getLedgerData={getLedgerData}
          res={accountInfo?.account_details}
          curr={props?.curr}
          getCompanyData2={getCompanyData2}
          getCompanyData1={props?.getCompanyData}
        />
      )}
    </div>
  );
}
